/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import * as types from '../mutation-types';

const state = {
  open: false,
};

const getters = {
  open: (state) => state.open,
};

const actions = {
  setNavigationStatus({ commit }, open) {
    commit(types.NAVIGATION_SET_STATUS, open);
  },
};

const mutations = {
  [types.NAVIGATION_SET_STATUS](state, open) {
    state.open = open;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
