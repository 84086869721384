<template>
    <div class="date-selector" v-if="availableDates">
        <date-picker is-expanded
                     ref="datePicker"
                     :columns="$screens({ default: 1, md: 2 })"
                     :is-inline="true" mode="range"
                     :available-dates="availableDates"
                     :value="innerValue" @input="change"/>
    </div>
</template>

<script>

import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import moment from 'moment';

export default {
  name: 'DateSelector',
  components: {
    DatePicker,
  },
  props: {
    value: {
      type: Object,
    },
    availableDates: {
      type: Array,
    },
  },
  data() {
    return {
      innerValue: {
        start: new Date(),
        end: new Date(),
      },
    };
  },
  methods: {
    change(event) {
      if (event && event.start && event.end) {
        const startFormatted = moment(event.start).format('YYYY-MM-DD');
        const endFormatted = moment(event.end).format('YYYY-MM-DD');
        this.$emit('input', { start: startFormatted, end: endFormatted });
      }
    },
    move(position) {
      if (this.$refs.datePicker) this.$refs.datePicker.$refs.calendar.move(position);
    },
  },
  watch: {
    value(val) {
      this.innerValue.start = new Date(val.start);
      this.innerValue.end = new Date(val.end);
    },
  },
  created() {
    this.innerValue.start = new Date(this.value.start);
    this.innerValue.end = new Date(this.value.end);
  },
};
</script>

<style>
    .date-selector .vc-container {
        font-family: Montserat, sans-serif;
        font-weight: 600;
        padding: 16px 16px 0;
        border: none;
        box-shadow: 0 3px 25px #00000010;

        --day-content-width: 38px;
        --day-content-height: 38px;
        --day-content-margin: 0;
        --day-content-bg-color-hover: hsla(211, 25%, 84%, 0.3);
        --day-content-dark-bg-color-hover: hsla(211, 25%, 84%, 0.3);
        --day-content-bg-color-focus: #5f24fb;
        --day-content-dark-bg-color-focus: #5f24fb;
        --highlight-height: 38px;
    }

    .date-selector .vc-title {
        font-family: "Montserrat", sans-serif;
        font-size: 16px;
        text-transform: uppercase;
        margin-bottom: 20px;
    }

    .date-selector .vc-weekday {
        font-family: "Oswald", sans-serif;
        color: #ABB9C3;
    }

    .date-selector .vc-day, .date-selector .vc-text-sm {
        font-size: 16px;
    }

    .date-selector .vc-text-blue-900 {
        color: #fff;
    }

    .date-selector .vc-highlight.vc-highlight-base-middle.vc-bg-blue-200,
    .date-selector .vc-highlight.vc-highlight-base-start,
    .date-selector .vc-highlight.vc-highlight-base-end {
        height: 38px;
    }

    .date-selector .vc-highlight.vc-rounded-full.vc-bg-blue-600,
    .date-selector .vc-highlight {
        background: #5f24fb;
        color: #fff;
        height: 38px;
        width: 38px;

        border-color: #5f24fb;
    }

    .date-selector .vc-grid-cell {
        margin-bottom: 12px;
    }

</style>
