<template>
    <div class="product-options">
        <div class="product-options__content">
            <button class="close-button product-options__close-button" @click="close"><span
                    class="close-button__icon"></span></button>

            <h3 class="title title--5 title--centered product-options__tagline">{{data.tagline}}</h3>
            <h4 class="title title--3 product-options__title">{{data.title}}</h4>

            <div class="product-options__options">

                <div v-for="(option, index) in data.options" :key="option.id" class="product-options__option">

                    <div class="radio-button">
                        <label :for="'option-' + index"
                               class="radio-button__label product-options__option-title"
                               :class="{ 'product-options__option-title--active': selectedOption === option.id }"
                        >{{option.title}}
                            <input type="radio"
                                   :value="option.id"
                                   :id="'option-' + index"
                                   :name="'option-' + index"
                                   :checked="option.id === selectedOption"
                                   @change="updateOption(option)"
                                   class="radio-button__input"/>
                            <i></i>
                        </label>
                        <div class="tab__content">
                          {{option.description}}
                        </div>
                    </div>

                    <div class="product-options__categories" v-if="option.id === selectedOption">
                        <div class="d-flex" v-if="option.categories">
                            <div class="tab" v-for="category in option.categories" :key="category.id"
                                 :class="[{'tab--active': category.id === selectedCategory.id}]">
                                <label :for="'category_' + category.id" class="tab__button">{{category.title}}
                                    <input type="radio"
                                           :value="category.id"
                                           :id="'category_' + category.id"
                                           :name="'category_' + option.id"
                                           :checked="category.id === selectedCategory.id"
                                           @change="updateCategory(category)"
                                           class="tab__input"/>
                                </label>
                            </div>

                            <div class="ml-auto product-options__price" v-html="selectedCategory.value"></div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center justify-content-lg-end">
              <button class="button button--block" @click="select">{{ __('product_options.confirm') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import Locales from '@/mixins/locales';

export default {
  name: 'ProductOptions',
  mixins: [Locales],
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      selectedOption: null,
      selectedCategory: null,
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
    updateOption(option) {
      this.selectedOption = option.id;
      // default category select

      const category = option.categories.find((c) => c.selected);
      this.selectedCategory = category || option.categories[option.categories.length - 1];
    },
    updateCategory(category) {
      this.selectedCategory = category;
    },
    select() {
      this.$emit('select', {
        id: this.data.id,
        option: this.selectedOption,
        category: this.selectedCategory ? this.selectedCategory.id : null,
      });
    },
  },
  created() {
    const option = this.data.options.find((o) => o.selected);
    if (option && option.categories) {
      const category = option.categories.find((c) => c.selected);
      this.selectedCategory = category;
    }
    this.selectedOption = option.id;
  },
};
</script>
