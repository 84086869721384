import Vue from 'vue';
import Router from 'vue-router';

import store from './store';
import tracking from './api/tracking';

import ResortsView from './views/ResortsView';
import DateView from './views/DateView';
import ActivityView from './views/ActivityView';
import GearView from './views/GearView';
import PickupView from './views/PickupView';
import ConfirmationView from './views/ConfirmationView';
import ProfileConfigView from './views/ProfileConfigView';
import SignUpView from './views/SignUpView';
import VerificationView from './views/VerificationView';
import SkipassView from './views/SkipassView';
import PaymentView from './views/PaymentView';

Vue.use(Router);

/**
 * Build a route guard function which will only let the navigation complete if
 * a certain condition of the 'selection' attribute in the store is met.
 * Otherwise, navigate to a fallback route.
 * @param selectionValidator
 * @param fallbackRoute
 */
function buildValidationGuard(selectionValidator, fallbackRoute) {
  return (to, from, next) => {
    const selection = store.getters['booking/selection'];
    if (!selectionValidator(selection)) next(fallbackRoute);
    else next();
  };
}

/**
 * Validate if the booking can be completed.
 * For this, each person has to have either some gear items and a valid profile config
 * or a skipass.
 * @param s - the selection
 */
function validatePersons(s) {
  return s.persons && s.persons.every((p) => (p.boothItemIds.length > 0 && s.profileConfigValid) || p.ticket);
}

const router = new Router({
  routes: [
    {
      path: '/',
      redirect: '/destinations',
    },
    {
      path: '/destinations',
      name: 'destinations',
      component: ResortsView,
    },
    {
      path: '/destinations/:id',
      name: 'destinations-by-id',
      component: ResortsView,
    },
    {
      path: '/date',
      name: 'date',
      component: DateView,
      beforeEnter: buildValidationGuard(
        (s) => s.resort,
        'destinations',
      ),
    },
    {
      path: '/activity',
      name: 'activity',
      component: ActivityView,
      beforeEnter: buildValidationGuard(
        (s) => s.date.start && s.date.end,
        'date',
      ),
    },
    {
      path: '/pickup',
      name: 'pickup',
      component: PickupView,
      beforeEnter: buildValidationGuard(
        (s) => s.persons && s.persons.length > 0,
        'activity',
      ),
    },
    {
      path: '/gear',
      name: 'gear',
      component: GearView,
      beforeEnter: buildValidationGuard(
        (s) => s.booth,
        'pickup',
      ),
    },
    {
      path: '/skipass',
      name: 'skipass',
      component: SkipassView,
      beforeEnter: buildValidationGuard(
        (s) => s.booth,
        'gear',
      ),
    },
    {
      path: '/profile-config',
      name: 'profile-config',
      component: ProfileConfigView,
      beforeEnter: buildValidationGuard(
        // Check that at least one person has at least one type of gear selected
        (s) => !!s.persons.find((p) => p.boothItemIds.length > 0),
        'gear',
      ),
    },
    {
      path: '/confirmation',
      name: 'confirmation',
      component: ConfirmationView,
      beforeEnter: buildValidationGuard(
        validatePersons,
        'profile-config',
      ),
    },
    {
      path: '/signup',
      name: 'signup',
      component: SignUpView,
      beforeEnter: buildValidationGuard(
        validatePersons,
        'confirmation',
      ),
    },
    {
      path: '/verification',
      name: 'verification',
      component: VerificationView,
      beforeEnter: buildValidationGuard(
        (s) => s.phoneNumber,
        'signup',
      ),
    },
    {
      path: '/payment',
      name: 'payment',
      component: PaymentView,
      beforeEnter: buildValidationGuard(
        (s) => s.token,
        'signup',
      ),
    },
    {
      path: '/success',
      name: 'success',
      beforeEnter: () => {
        tracking.track('reached_successful_reservation');
        window.location.href = 'https://hyll.com/booking-success';
      },
    },
  ],
});

// Redirect all to hyll.com if booking is disabled.
router.beforeEach(() => {
  window.location.href = 'https://hyll.com';
});

export default router;
