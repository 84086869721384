<template>
    <div class="activity-select">
      <div class="row no-gutters justify-content-between">
        <div class="activity-select__title">{{title}}</div>
        <button
            class="remove-button"
            @click="removePersonButton(id)"
            v-if="removeable"
        ><span class="remove-button__icon"></span></button>
      </div>
      <form class="row mt-3 align-items-center">
        <div class="col-6 col-sm-3">
          <input  type="text"
                  class="input activity-select__input"
                  name="firstname"
                  :placeholder="__('activity_select.first_name')"
                  v-model="firstName"
                  @keyup="updateInfos">
        </div>
        <div class="col-6 col-sm-3">
          <input type="text"
                 class="input activity-select__input"
                 name="lastname"
                 v-model="lastName"
                 :placeholder="__('activity_select.last_name')"
                 @keyup="updateInfos"/>
        </div>
        <ValidationProvider
            tag="div" class="form__group-inner col-12 col-sm-3 col-md-2"
            name="Alter"
            rules="numeric"
            v-slot="{ errors }"
        >
          <input type="number"
                 class="input activity-select__input"
                 name="age"
                 v-model="age"
                 :placeholder="__('activity_select.age')"
                 @keyup="updateInfos"/>
          <span v-if="errors.length > 0" class="input__error">{{ errors[0] }}</span>
        </ValidationProvider>

        <div class="mt-sm-3 mt-md-0 col-md-4 col-lg-3">
          <div class="title title--overline mt-2 d-md-none ">{{ __('activity_select.choose_activity')}}</div>
          <select name="activity"
                  class="input input--select activity-select__input mt-0"
                  v-model="selectedActivity"
          >
            <option v-for="activity in activities" :key="activity.id"
                    :value="activity.id"
            >{{ activity.name }}</option>
          </select>
        </div>
      </form>
    </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex';
import Locales from '../mixins/locales';
import Tracking from '../mixins/tracking';

export default {
  name: 'ActivitySelect',
  mixins: [Locales, Tracking],
  props: {
    person: {
      type: Object,
    },
    activities: {
      type: Array,
    },
    title: {
      type: String,
    },
    removeable: {
      type: Boolean,
    },
  },
  data() {
    return {
      id: null,
      selectedActivity: null,
      firstName: null,
      lastName: null,
      age: null,
    };
  },
  computed: {
    ...mapGetters('booking', [
      'selection',
    ]),
  },
  methods: {
    ...mapActions('booking', [
      'updatePerson',
      'removePerson',
    ]),
    updateInfos() {
      this.updatePerson({
        id: this.person.id,
        firstName: this.firstName,
        lastName: this.lastName,
        age: this.age,
        activity: this.selectedActivity,
      });
    },
    removePersonButton(id) {
      this.track('clicked_remove_person');
      this.removePerson(id);
    },
  },
  watch: {
    person: {
      handler(val) {
        if (this.person.activity) {
          this.selectedActivity = val.activity;
        }
      },
      deep: true,
    },
    activities() {
      // Once activities become available, preselect the first one if there is no selection
      if (this.person.activity === null) {
        this.selectedActivity = this.activities[0].id;
      }
    },
    selectedActivity() {
      if (this.activities) this.updateInfos();
    },
  },
  created() {
    this.id = this.person.id;
    this.firstName = this.person.firstName;
    this.lastName = this.person.lastName;
    this.age = this.person.age;

    if (this.person.activity) {
      this.selectedActivity = this.person.activity;
    } else if (this.activities) {
      this.selectedActivity = this.activities[0].id;
    }
  },
};
</script>
