<template>
    <div class="bottom-navigation d-flex justify-content-between">
        <button class="button button--secondary button--prev" @click="prev()">
          <span class="d-none d-sm-block">{{prevLabel}}</span>
          <span class="button__icon"></span>
        </button>
        <button v-if="nextRoute" class="button button--next" :class="{'button--disabled': nextDisabled, 'button--loading': loading}"
                @click="next()"
        >
          <span>{{nextLabel}}</span>
          <span class="button__icon"></span>
        </button>
    </div>
</template>

<script>

export default {
  name: 'BottomNavigation',
  props: {
    prevLabel: {
      type: String,
    },
    prevRoute: {
      type: String,
    },
    nextLabel: {
      type: String,
    },
    nextRoute: {
      type: String,
    },
    nextDisabled: {
      type: Boolean,
    },
    beforeNext: {
      type: Function,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    prev() {
      if (this.prevRoute) {
        this.$router.push(this.prevRoute);
      } else {
        this.$router.go(-1);
      }
    },
    async next() {
      if (!this.nextDisabled) {
        if (this.beforeNext) {
          this.loading = true;
          if (await this.beforeNext()) {
            this.push();
          }
          this.loading = false;
        } else {
          this.push();
        }
      }
    },
    push() {
      this.$emit('next');
      this.$router.push(this.nextRoute);
    },
  },
};
</script>
