<template>
  <div :class="['sub-navigation', {'sub-navigation--open' : navOpen}]" v-if="navigationItems">
    <div class="container">
      <ul class="sub-navigation__items">
        <div class="row">
          <div class="col-12 col-sm-3 col-md-2" v-for="item in navigationItems" :key="item.id">
            <li class="sub-navigation__list">
              <span class="sub-navigation__subtitle" :class="item.classes" v-html="item.label"></span>

              <ul class="sub-navigation__children">
                <li class="sub-navigation__item" v-for="child in item.children" :key="child.id">
                  <a class="sub-navigation__link" :class="child.classes" :href="child.url" v-html="child.label"></a>
                </li>
              </ul>
            </li>
          </div>
          <div class="col-md-4">
            <div class="sub-navigation__social-media-items social-media-items d-flex justify-content-end">
              <div class="some-item" v-for="someItem in someItems" :key="someItem.icon.url">
                <a class="some-item__link" :href="someItem.link.url" :target="someItem.link.target">
                  <img class="some-item__image" :src="someItem.icon.url" :alt="someItem.icon.alt">
                </a>
              </div>
            </div>
          </div>
        </div>
      </ul>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    modeNeg: {
      type: Boolean,
      default: false,
    },
    navigationItems: {
      type: Array,
      default: null,
    },
    someItems: {
      type: Array,
      default: null,
    },
  },
  computed: {
    ...mapGetters('header', {
      navOpen: 'open',
    }),
  },
  data() {
    return {};
  },
  mounted() {},
};
</script>
