<template>
    <div class="booth-select" v-if="selection.booth">
        <div class="row">
            <div class="col-md-5 col-lg-4 order-1 order-md-0">
                <div class="booth-select__list">
                    <div v-for="(booth, index) in booths" :key="booth.id" class="d-flex booth-select__booth"
                         :class="{'booth-select__booth--active': isSelected(booth)}"
                         @click="select(booth)">
                        <div class="booth-select__image-container">
                            <img :src="booth.image" class="booth-select__image"/>
                        </div>
                        <div class="booth-select__pin d-flex justify-content-center">
                            <div class="pin"
                                 :class="['pin--'+char(index), {'pin--active': isSelected(booth)}]"></div>
                        </div>
                        <div class="booth-select__info-container">
                            <div class="booth-select__info">{{booth.info}}</div>
                            <div class="booth-select__text booth-select__text--strong">{{booth.title}}</div>
                            <div class="booth-select__text">{{booth.address}}</div>
                            <div class="booth-select__date">{{getDate(booth)}}</div>
                        </div>
                        <div class="booth-select__radio-button ml-auto">
                            <label :for="'booth-'+booth.id" class="radio-button__label">
                                <input type="radio"
                                       :id="'booth-'+booth.id"
                                       :checked="isSelected(booth)"
                                       class="radio-button__input"/>
                                <i></i>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-7 col-lg-8 order-0 order-md-1" v-if="initialMapPosition">
                <div class="booth-select__map-container">
                    <gmap-map
                            ref="boothMap"
                            :center="initialMapPosition"
                            :zoom=16
                            :options="mapOptions"
                            style="height: 100%;">
                        <template>
                            <gmap-marker
                                    v-for="(booth, index) in booths"
                                    :key="booth.id"
                                    :position="getBoothPosition(booth)"
                                    :zIndex="index"
                                    :clickable="true"
                                    :icon="setIcon(booth, index)"
                                    @click="selectBooth(booth)">
                            </gmap-marker>
                        </template>
                    </gmap-map>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Vue from 'vue';
import * as VueGoogleMaps from 'vue2-google-maps';
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import Locales from '../mixins/locales';
import Tracking from '../mixins/tracking';

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_KEY,
  },
});

function isMobile() {
  return Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) <= 1024;
}

export default {
  name: 'BoothSelect',
  mixins: [Locales, Tracking],
  props: {
    booths: {
      type: Array,
    },
  },
  data() {
    return {
      initialMapPosition: null,
      mapOptions: {
        mapTypeId: 'satellite',
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeControl: !isMobile(),
        zoomControl: !isMobile(),
      },
    };
  },
  computed: {
    ...mapGetters('booking', [
      'selection',
    ]),
  },
  methods: {
    ...mapActions('booking', [
      'selectBooth',
    ]),
    char(index) {
      return 'abcdefghijklmnopqrstuvwxyz'.charAt(index);
    },
    isSelected(booth) {
      return this.selection.booth && this.selection.booth.id === booth.id;
    },
    getBoothPosition(booth) {
      return { lat: parseFloat(booth.lat), lng: parseFloat(booth.lng) };
    },
    getDate(booth) {
      const weekDay = moment(this.selection.date.start).format('dd').toUpperCase();
      const openingHours = booth.opening_hours.find((item) => item[weekDay])[weekDay];

      let date = moment(this.selection.date.start).locale(this.__language).format('dd');
      if (openingHours.start_time && openingHours.end_time) {
        date += `, ${ openingHours.start_time } - ${ openingHours.end_time}`;
      }
      return date;
    },
    select(booth) {
      this.track('clicked_on_booth', {
        booth_id: booth.id,
        booth_name: booth.name,
      });
      this.selectBooth(booth);

      if (this.$refs.boothMap) {
        this.$refs.boothMap.$mapPromise.then((map) => {
          map.setZoom(16);
          map.panTo({ lat: booth.lat, lng: booth.lng });
        });
      }
    },
    setIcon(booth, index) {
      if (this.selection.booth && this.selection.booth.id === booth.id) {
        // eslint-disable-next-line global-require,import/no-dynamic-require
        return require(`../assets/pin_${this.char(index)}.svg`);
      }
      // eslint-disable-next-line global-require,import/no-dynamic-require
      return require(`../assets/pin_${this.char(index)}_black.svg`);
    },
    async zoomToBooths(booths) {
      await this.$gmapApiPromiseLazy();
      const google = VueGoogleMaps.gmapApi();
      const map = await this.$refs.boothMap.$mapPromise;

      if (booths.length > 1) {
        const allBoothBounds = new google.maps.LatLngBounds();
        booths.forEach((booth) => allBoothBounds.extend(booth));
        map.fitBounds(allBoothBounds);
      } else {
        map.setZoom(16);
      }
    },
  },
  created() {
    if (!this.selection.booth) {
      this.select(this.booths[0]);
    }

    this.initialMapPosition = { lat: parseFloat(this.selection.booth.lat), lng: parseFloat(this.selection.booth.lng) };
  },
  mounted() {
    this.zoomToBooths(this.booths);
  },
};
</script>
