<template>
    <div class="activity-view">
        <site-header :title="selection.resort.name" :sub-title="formattedDate"></site-header>

        <div class="content container">
          <div class="row">
            <div class="col-12">
              <div class="title title--centered title--5">{{ __('activity_view.title') }}</div>
            </div>
          </div>

          <div v-for="(person, index) in selection.persons" :key="'activity_select_'+index">
            <activity-select :person="person"
                             :activities="activities"
                             :class="{'activity-select--last' : selection.persons.length === index + 1}"
                             :title="getTitle(index)"
                             :removeable="selection.persons.length > 1 && index !== 0"></activity-select>
          </div>

          <div class="row mt-3">
            <div class="col-12">
              <button
                  @click="addPersonButton"
                  class="add-button add-button--person activity-view__add-person-button"
              >
                <span class="d-block">{{ __('activity_view.add_person') }}</span>
                <span class="add-button__icon"></span>
              </button>
            </div>
          </div>
      </div>
      <div class="content__bottom-navigation">
          <div class="container">
              <div class="row">
                  <div class="col-12">
                      <bottom-navigation
                          :prev-label="__('bottom_navigation.date')"
                          prev-route="date"
                          :next-label="__('bottom_navigation.pickup')"
                          next-route="pickup"
                          :next-disabled="!complete"
                      ></bottom-navigation>
                  </div>
              </div>
          </div>
      </div>
    </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import BookingApi from '../api/booking';
import Locale from '../mixins/locales';
import Tracking from '../mixins/tracking';
import SiteHeader from '../components/SiteHeader';
import BottomNavigation from '../components/BottomNavigation';
import ActivitySelect from '../components/ActivitySelect';

export default {
  name: 'ActivityView',
  components: { ActivitySelect, BottomNavigation, SiteHeader },
  mixins: [Locale, Tracking],
  data() {
    return {
      activities: null,
    };
  },
  computed: {
    ...mapGetters('booking', [
      'selection',
      'formattedDate',
    ]),
    complete() {
      let complete = true;

      this.selection.persons.forEach((person) => {
        if (!person.firstName || !person.lastName || !person.activity || !person.age || (person.age && Number.isNaN(person.age))) {
          complete = false;
        }
      });
      return complete;
    },
  },
  methods: {
    ...mapActions('booking', [
      'addPerson',
    ]),
    getTitle(index) {
      if (index === 0) {
        return this.__('activity_view.first_person');
      }
      return this.__('activity_view.person_nr') + (index + 1);
    },
    load() {
      BookingApi.loadActivities(this.selection.resort.id, this.selection.date).then((response) => {
        this.activities = response.data.data;
      });
    },
    addPersonButton() {
      this.addPerson();
      this.track('clicked_add_person');
      this.$nextTick(() => {
        document.querySelector('.activity-select--last')
          .scrollIntoViewIfNeeded({ behaviour: 'smooth' });
      });
    },
  },
  watch: {
    __language() {
      this.load();
    },
  },
  mounted() {
    this.track('reached_activity_selection');
    this.load();
    // add first person if not present
    if (this.selection.persons.length === 0) {
      this.addPerson();
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.complete) {
      const persons = this.selection.persons.map((p) => ({
        first_name: p.firstName,
        last_name: p.lastName,
        age: p.age,
        activity: p.activity,
      }));

      this.track('clicked_confirmed_persons', { persons });
    }
    next();
  },
};
</script>
