<template>
  <div class="profile-config-view">
    <site-header :title="selection.resort.name" :sub-title="formattedDate"></site-header>

    <div class="content">
      <validation-observer slim v-slot="{ valid, invalid }">
        <profile-config-valid-listener :valid="valid"></profile-config-valid-listener>
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="title title--centered title--5">{{ __('profile_config_view.title') }}</div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6"
                 v-for="profile in selection.persons"
                 :key="profile.id"
            >
              <profile-config-form
                  :profile="profile"
              ></profile-config-form>
            </div>
          </div>
        </div>

        <div class="content__bottom-navigation">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <bottom-navigation
                    v-if="hasTickets"
                    :prev-label="__('bottom_navigation.skipass')"
                    prev-route="skipass"
                    :next-label="__('bottom_navigation.confirmation')"
                    next-route="confirmation"
                    :next-disabled="invalid"
                ></bottom-navigation>
                <bottom-navigation
                    v-else
                    :prev-label="__('bottom_navigation.gear')"
                    prev-route="gear"
                    :next-label="__('bottom_navigation.confirmation')"
                    next-route="confirmation"
                    :next-disabled="invalid"
                ></bottom-navigation>
              </div>
            </div>
          </div>
        </div>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SiteHeader from '../components/SiteHeader';
import BottomNavigation from '../components/BottomNavigation';
import ProfileConfigForm from '../components/profile-config-form/ProfileConfigForm';
import ProfileConfigValidListener from '../components/profile-config-form/ProfileConfigValidListener';
import Locale from '../mixins/locales';
import Tracking from '../mixins/tracking';

export default {
  name: 'ProfileConfigView',
  components: {
    ProfileConfigValidListener, ProfileConfigForm, SiteHeader, BottomNavigation,
  },
  mixins: [Locale, Tracking],
  computed: {
    ...mapGetters('booking', [
      'selection',
      'formattedDate',
      'hasTickets',
    ]),
  },
  mounted() {
    this.track('reached_personal_configuration');
  },
  beforeRouteLeave(to, from, next) {
    if (this.selection.profileConfigValid) {
      this.selection.persons.forEach((p) => {
        this.track('clicked_confirm_personal_configuration', {
          level: p.level,
          weight: p.weight,
          height: p.height,
          shoe_size: p.shoe_size,
          shoe_sole_length: p.shoe_sole_length,
          foot_position: p.foot_position,
        });
      });
    }
    next();
  },
};
</script>
