<template>
    <div class="resort-view">
        <site-header :title="__('resorts_view.site_header_title')" :big=true></site-header>

        <div class="content">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h2 class="title title--centered title--5">{{__('resorts_view.title')}}</h2>
                    </div>
                </div>

                <div class="row justify-content-center">
                    <div class="col-12 col-lg-4 d-flex justify-content-center">
                        <resort-search class="resort-view__resort-search" @select="select"></resort-search>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-sm-6 col-lg-4" v-for="resort in resorts" :key="resort.id">
                        <select-card :value="resort" :name="resort.name" :background-image="resort.image"
                                     @select="select"></select-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex';
import BookingApi from '../api/booking';
import SelectCard from '../components/SelectCard';
import SiteHeader from '../components/SiteHeader';
import Locales from '../mixins/locales';
import Tracking from '../mixins/tracking';
import ResortSearch from '../components/ResortSearch';

export default {
  name: 'DestinationsView',
  components: { ResortSearch, SiteHeader, SelectCard },
  mixins: [Locales, Tracking],
  props: {
    msg: String,
  },
  computed: {
    ...mapGetters('booking', [
      'selection',
    ]),
  },
  data() {
    return {
      resorts: null,
    };
  },
  methods: {
    ...mapActions('booking', [
      'selectResort',
    ]),
    load() {
      BookingApi.loadResorts().then((response) => {
        if (this.$route.params.id) {
          const resort = response.data.data.find((r) => r.id === parseInt(this.$route.params.id));
          if (resort) {
            this.select(resort);
            return;
          }
        }
        this.resorts = response.data.data;
      });
    },
    select(resort) {
      // analytics
      this.track('clicked_on_resort', {
        resort_id: resort.id,
        resort_name: resort.name,
        introductory_price_active: false,
        // 'min_price': 0,
        // 'max_price': 0
      });

      this.selectResort(resort);
      this.$router.push('/date');
    },
  },
  mounted() {
    this.load();
    // analytics
    this.track('reached_resort_selection', {
      introductory_price_active: false,
    });
  },
};
</script>
