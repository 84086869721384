<template>
    <div class="payment-form">
        <div class="payment-form__title">{{__('payment_form.title')}}</div>
        <form id="payment-form">
            <div id="card-element" class="payment-form__card-element"><!--Stripe.js injects the Card Element--></div>
            <p id="card-error" role="alert"></p>
        </form>
    </div>
</template>

<script>

import Locales from '../mixins/locales';

export default {
  name: 'PaymentForm',
  mixins: [Locales],
  props: {
    stripe: {
      type: Object,
    },
  },
  data() {
    return {
      card: null,
    };
  },
  methods: {
    getPaymentMethod() {
      return this.stripe.createPaymentMethod({
        type: 'card',
        card: this.card,
      }).then((result) => {
        if (result.error) {
          // Inform the user if there was an error.
          const errorElement = document.getElementById('card-errors');
          errorElement.textContent = result.error.message;
          return null;
        }
        return result.paymentMethod;
      });
    },
    async init() {
      const elements = this.stripe.elements();
      const style = {
        base: {
          color: '#32325d',
          fontFamily: 'Arial, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#32325d',
          },
        },
        invalid: {
          fontFamily: 'Arial, sans-serif',
          color: '#fa755a',
          iconColor: '#fa755a',
        },
      };
      this.card = elements.create('card', { style });
      this.card.mount('#card-element');

      this.card.on('change', this.onCardChange);
    },
    async onCardChange(event) {
      let method = null;
      if (event.complete) {
        method = await this.getPaymentMethod();
      }
      this.$emit('update', { paymentMethod: method, card: this.card });
    },
  },
  mounted() {
    this.init();
  },
};
</script>
