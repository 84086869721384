<template>
    <div class="pickup-view">
        <site-header :title="selection.resort.name" :sub-title="formattedDate"></site-header>
        <div class="content">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12">
                        <h2 class="title title--centered title--5">{{__('pickup_view.title')}}</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12" v-if="booths">
                        <booth-select :booths="booths"></booth-select>
                    </div>
                </div>
            </div>
            <div class="content__bottom-navigation">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <bottom-navigation
                                :prev-label="__('bottom_navigation.activity')"
                                prev-route="activity"
                                :next-label="__('bottom_navigation.gear')"
                                next-route="gear"
                                :next-disabled="!complete"
                            ></bottom-navigation>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import BookingApi from '../api/booking';
import Locale from '../mixins/locales';
import Tracking from '../mixins/tracking';
import SiteHeader from '../components/SiteHeader';
import BottomNavigation from '../components/BottomNavigation';
import BoothSelect from '../components/BoothSelect';

export default {
  name: 'PickupView',
  components: { BoothSelect, BottomNavigation, SiteHeader },
  mixins: [Locale, Tracking],
  data() {
    return {
      booths: null,
    };
  },
  computed: {
    ...mapGetters('booking', [
      'selection',
      'formattedDate',
    ]),
    complete() {
      return this.selection.booth;
    },
  },
  methods: {
    ...mapActions('booking', [

    ]),
    load() {
      const activities = this.selection.persons.map((person) => person.activity);
      BookingApi.loadPickupBooths(this.selection.resort.id, this.selection.date, activities).then((response) => {
        this.booths = response.data.data;
      });
    },
  },
  watch: {
    __language() {
      this.load();
    },
  },
  mounted() {
    this.track('reached_booth_selection');
    this.load();
  },
  beforeRouteLeave(to, from, next) {
    if (this.selection.booth) {
      this.track('clicked_select_booth', {
        booth_id: this.selection.booth.id,
        booth_name: this.selection.booth.name,
      });
    }
    next();
  },
};
</script>
