/* BOOKING */
export const BOOKING_RESET_SELECTION = 'BOOKING_RESET_SELECTION';
export const BOOKING_ADD_PERSON = 'BOOKING_ADD_PERSON';
export const BOOKING_LOAD_ACTIVITIES = 'BOOKING_LOAD_ACTIVITIES';
export const BOOKING_LOAD_GEAR_TYPES = 'BOOKING_LOAD_GEAR_TYPES';
export const BOOKING_LOAD_OPENING_INFORMATION = 'BOOKING_LOAD_OPENING_INFORMATION';
export const BOOKING_LOAD_RESORTS = 'BOOKING_LOAD_RESORTS';
export const BOOKING_REMOVE_PERSON = 'BOOKING_REMOVE_PERSON';
export const BOOKING_SELECT_BOOTH = 'BOOKING_SELECT_BOOTH';
export const BOOKING_SELECT_DATE = 'BOOKING_SELECT_DATE';
export const BOOKING_SELECT_RESORT = 'BOOKING_SELECT_RESORT';
export const BOOKING_RESET_GEAR = 'BOOKING_RESET_GEAR';
export const BOOKING_SET_GEAR_TYPES = 'BOOKING_SET_GEAR_TYPES';
export const BOOKING_SET_PRICE = 'BOOKING_SET_PRICE';
export const BOOKING_UPDATE_PERSON = 'BOOKING_UPDATE_PERSON';
export const BOOKING_SET_PROFILE_CONFIG_VALID = 'BOOKING_SET_PROFILE_CONFIG_VALID';
export const BOOKING_SET_PROFILE_CONFIG_NEEDED = 'BOOKING_SET_PROFILE_CONFIG_NEEDED';
export const BOOKING_SET_HAS_TICKETS = 'BOOKING_SET_HAS_TICKETS';
export const BOOKING_UPDATE_EMAIL = 'BOOKING_UPDATE_EMAIL';
export const BOOKING_UPDATE_PHONE_NUMBER = 'BOOKING_UPDATE_PHONE_NUMBER';
export const BOOKING_UPDATE_TOKEN = 'BOOKING_UPDATE_TOKEN';

/* NAVIGATION */
export const NAVIGATION_SET_STATUS = 'NAVIGATION_SET_STATUS';
/* LOCALIZATION */
export const LOCALIZATION_SET_AVAILABLE_LANGUAGES = 'LOCALIZATION_SET_AVAILABLE_LANGUAGES';
export const LOCALIZATION_SET_LANGUAGE = 'LOCALIZATION_SET_LANGUAGE';
export const LOCALIZATION_SET_LOCALES = 'LOCALIZATION_SET_LOCALES';
