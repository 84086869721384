/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import Vue from 'vue';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import * as types from '../mutation-types';
import BookingApi from '../../api/booking';

// initial state
const state = {
  selection: {
    resort: null,
    date: {
      start: null,
      end: null,
    },
    persons: [],
    booth: null,
    profileConfigValid: false,
    email: null,
    phoneNumber: null,
    token: null,
  },
  navigation: {
    profileConfigNeeded: true,
    hasTickets: false,
  },
  price: null,
};

// getters
const getters = {
  activities: (state) => state.activities,
  formattedDate(state) {
    if (state.selection.date.start && state.selection.date.end) {
      const start = moment(state.selection.date.start).format('DD.MM.YYYY');
      const end = moment(state.selection.date.end).format('DD.MM.YYYY');
      let formattedDate = start;
      if (start !== end) {
        formattedDate = `${formattedDate } - ${ end}`;
      }
      return formattedDate;
    }

    return null;
  },
  selection: (state) => state.selection,
  email: (state) => state.selection.email,
  phoneNumber: (state) => state.selection.phoneNumber,
  price: (state) => state.price,
  token: (state) => state.selection.token,
  profileConfigNeeded: (state) => state.navigation.profileConfigNeeded,
  hasTickets: (state) => state.navigation.hasTickets,
};

// actions
const actions = {
  addPerson({ commit }) {
    commit(types.BOOKING_ADD_PERSON);
  },
  removePerson({ commit }, id) {
    commit(types.BOOKING_REMOVE_PERSON, id);
  },
  selectBooth({ commit }, booth) {
    commit(types.BOOKING_RESET_GEAR);
    commit(types.BOOKING_SELECT_BOOTH, booth);
  },
  async selectDate({ commit }, date) {
    commit(types.BOOKING_SELECT_DATE, date);
  },
  selectResort({ commit }, resort) {
    commit(types.BOOKING_RESET_SELECTION);
    commit(types.BOOKING_SELECT_RESORT, resort);
  },
  setGearTypes({ commit }, payload) {
    commit(types.BOOKING_SET_GEAR_TYPES, payload);
  },
  updatePerson({ commit }, payload) {
    commit(types.BOOKING_UPDATE_PERSON, payload);
  },
  async checkProfileConfigNeeded({ state, commit }) {
    const allGearTypes = Array.from(new Set(
      state.selection.persons.reduce((a, p) => [...a, ...p.gearTypes], []),
    ));
    const profileConfigResponse = await BookingApi.getProfileConfig(allGearTypes);

    if (profileConfigResponse.data.questions.length === 0) {
      commit(types.BOOKING_SET_PROFILE_CONFIG_VALID, true);
      commit(types.BOOKING_SET_PROFILE_CONFIG_NEEDED, false);
    } else {
      commit(types.BOOKING_SET_PROFILE_CONFIG_NEEDED, true);
    }
  },
  setProfileConfigValid({ commit }, payload) {
    commit(types.BOOKING_SET_PROFILE_CONFIG_VALID, payload);
  },
  setHasTickets({ commit }, payload) {
    commit(types.BOOKING_SET_HAS_TICKETS, payload);
  },
  updateEmail({ commit }, email) {
    commit(types.BOOKING_UPDATE_EMAIL, email);
  },
  updatePhoneNumber({ commit }, phoneNumber) {
    commit(types.BOOKING_UPDATE_PHONE_NUMBER, phoneNumber);
  },
  updateToken({ commit }, token) {
    commit(types.BOOKING_UPDATE_TOKEN, token);
  },
  async getPrice({ state, commit }) {
    const profiles = state.selection.persons.map((person) => ({
      activity_id: person.activity,
      booth_item_ids: person.boothItemIds,
      ticket: person.ticket,
      profile: {
        first_name: person.firstName,
        last_name: person.lastName,
        age: person.age,
      },
    }));

    const priceRequest = {
      resort_id: state.selection.resort.id,
      booth_id: state.selection.booth.id,
      start_date: moment(state.selection.date.start).format('YYYY-MM-DD'),
      end_date: moment(state.selection.date.end).format('YYYY-MM-DD'),
      profiles,
    };

    const response = await BookingApi.getPrice(priceRequest);
    commit(types.BOOKING_SET_PRICE, response.data);
  },
};

// mutations
const mutations = {
  [types.BOOKING_RESET_SELECTION](state) {
    state.selection = {
      resort: null,
      date: {
        start: null,
        end: null,
      },
      persons: [],
      booth: null,
      profileConfigValid: false,
      email: null,
      phoneNumber: null,
      token: null,
    };
  },
  [types.BOOKING_ADD_PERSON](state) {
    state.selection.persons.push({
      id: uuidv4(),
      firstName: null,
      lastName: null,
      activity: null,
      age: null,
      level: null,
      height: null,
      weight: null,
      shoe_size: null,
      shoe_sole_length: null,
      foot_position: null,
      boothItemIds: [],
      gearTypes: [],
      ticket: null,
    });
  },
  [types.BOOKING_REMOVE_PERSON](state, id) {
    state.selection.persons = state.selection.persons.filter((p) => p.id !== id);
  },
  [types.BOOKING_SELECT_BOOTH](state, booth) {
    state.selection.booth = booth;
  },
  [types.BOOKING_SELECT_DATE](state, date) {
    state.selection.date.start = date.start.toString();
    state.selection.date.end = date.end.toString();
  },
  [types.BOOKING_SELECT_RESORT](state, resort) {
    state.selection.resort = resort;
  },
  [types.BOOKING_SET_GEAR_TYPES](state, data) {
    state.selection.persons.forEach((person, index) => {
      if (person.id === data.id) {
        state.selection.persons[index].gearTypes = data.gearTypes;
      }
    });
  },
  [types.BOOKING_RESET_GEAR](state) {
    state.selection.persons.forEach((person, index) => {
      state.selection.persons[index].gearTypes = [];
      state.selection.persons[index].boothItemIds = [];
    });
  },
  [types.BOOKING_UPDATE_PERSON](state, data) {
    state.selection.persons.forEach((person, index) => {
      if (person.id === data.id) {
        Object.keys(data).forEach((key) => {
          Vue.set(state.selection.persons[index], key, data[key]);
        });
      }
    });
  },
  [types.BOOKING_SET_PROFILE_CONFIG_VALID](state, valid) {
    state.selection.profileConfigValid = valid;
  },
  [types.BOOKING_SET_PROFILE_CONFIG_NEEDED](state, needed) {
    state.navigation.profileConfigNeeded = needed;
  },
  [types.BOOKING_SET_HAS_TICKETS](state, hasTickets) {
    state.navigation.hasTickets = hasTickets;
  },
  [types.BOOKING_UPDATE_EMAIL](state, email) {
    state.selection.email = email;
  },
  [types.BOOKING_UPDATE_PHONE_NUMBER](state, phoneNumber) {
    state.selection.phoneNumber = phoneNumber;
  },
  [types.BOOKING_UPDATE_TOKEN](state, token) {
    state.selection.token = token;
  },
  [types.BOOKING_SET_PRICE](state, price) {
    state.price = price;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
