<template>
    <div :class="['site-header d-flex justify-content-center', {'site-header--big' : big}]"
         :style="[headerImage ? {'background-image': 'url(' + headerImage + ')'} : null]">

        <div class="d-flex align-self-center flex-column">
            <language-switch></language-switch>
            <div class="site-header__title">{{title}}</div>
            <div class="site-header__subtitle">{{subTitle}}</div>
        </div>
    </div>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/remote-config';

import LanguageSwitch from './LanguageSwitch';

export default {
  name: 'SiteHeader',
  components: {
    LanguageSwitch,
  },
  props: {
    title: {
      type: String,
    },
    subTitle: {
      type: String,
    },
    image: {
      type: String,
    },
    big: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fallbackImage: null,
    };
  },
  computed: {
    headerImage() {
      if (this.image) {
        return this.image;
      }

      if (this.fallbackImage) {
        return this.fallbackImage;
      }

      return null;
    },
  },
  created() {
    const remoteConfig = firebase.remoteConfig();
    this.fallbackImage = remoteConfig.getValue('site_header_image')._value;
  },
};
</script>
