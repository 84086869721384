<template>
    <div class=" activity-view">
        <site-header :title="selection.resort.name" :sub-title="formattedDate"></site-header>

        <div class="content">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h2 class="title title--centered title--5">{{ __('gear_view.title') }}</h2>
                    </div>
                </div>
            </div>
            <div v-for="(person, index) in selection.persons" :key="'activity_select_'+index">
                <gear-type-select :person="person" @load="updateTickets"></gear-type-select>
            </div>

            <div class="content__bottom-navigation">
                <div class="container">
                    <div class="row">
                        <div class="col-12">

                            <bottom-navigation
                                v-if="hasTickets"
                                :prev-label="__('bottom_navigation.pickup')"
                                prev-route="pickup"
                                :next-label="__('bottom_navigation.skipass')"
                                next-route="skipass"
                                :next-disabled="!complete"
                            ></bottom-navigation>
                            <bottom-navigation
                                v-else-if="profileConfigNeeded"
                                :prev-label="__('bottom_navigation.pickup')"
                                prev-route="pickup"
                                :next-label="__('bottom_navigation.profile_config')"
                                next-route="profile-config"
                                :next-disabled="!complete"
                            ></bottom-navigation>
                            <bottom-navigation
                                v-else
                                :prev-label="__('bottom_navigation.pickup')"
                                prev-route="pickup"
                                :next-label="__('bottom_navigation.confirmation')"
                                next-route="confirmation"
                                :next-disabled="!complete"
                            ></bottom-navigation>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import SiteHeader from '../components/SiteHeader';
import BottomNavigation from '../components/BottomNavigation';
import GearTypeSelect from '../components/GearTypeSelect';
import Locale from '../mixins/locales';
import Tracking from '../mixins/tracking';

export default {
  name: 'GearView',
  components: { GearTypeSelect, BottomNavigation, SiteHeader },
  mixins: [Locale, Tracking],
  computed: {
    ...mapGetters('booking', [
      'selection',
      'formattedDate',
      'profileConfigNeeded',
      'hasTickets',
    ]),
    complete() {
      let complete = true;
      this.selection.persons.forEach((person) => {
        if (person.boothItemIds.length === 0) {
          complete = false;
        }
      });
      return complete || this.hasTickets;
    },
  },
  methods: {
    ...mapActions('booking', [
      'checkProfileConfigNeeded',
      'setHasTickets',
    ]),
    getName(person) {
      return `${person.firstName } ${ person.lastName}`;
    },
    updateTickets(event) {
      this.setHasTickets(event.catalogue.tickets.length > 0);
    },
  },
  mounted() {
    this.checkProfileConfigNeeded();
    this.track('reached_gear_selection');
  },
  beforeRouteLeave(to, from, next) {
    const gearTypes = this.selection.persons.reduce((a, p) => [...a, ...p.gearTypes], []);
    const boothItemIds = this.selection.persons.reduce((a, p) => [...a, ...p.boothItemIds], []);

    if (this.complete) {
      this.track('clicked_select_gears', {
        gear_ids: gearTypes,
        price_categories: boothItemIds,
      });
    }
    next();
  },
};
</script>
