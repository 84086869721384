<template>
    <div class="skipass-view">
        <site-header :title="selection.resort.name" :sub-title="formattedDate"></site-header>
        <div class="content">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12">
                        <h2 class="title title--centered title--5">{{__('skipass_view.title')}}</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-6" v-for="person in selection.persons" :key="person.id">
                        <skipass-select :person="person"></skipass-select>
                    </div>
                </div>
            </div>
            <div class="content__bottom-navigation">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <bottom-navigation
                                :prev-label="__('bottom_navigation.gear')"
                                prev-route="gear"
                                :next-label="profileConfigNeeded ? __('bottom_navigation.profile_config') : __('bottom_navigation.confirmation')"
                                :next-route="profileConfigNeeded ? 'profile-config' : 'confirmation'"
                                :next-disabled="!complete"
                            ></bottom-navigation>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SiteHeader from '../components/SiteHeader';
import BottomNavigation from '../components/BottomNavigation';
import Locales from '../mixins/locales';
import SkipassSelect from '../components/SkipassSelect';

export default {
  name: 'SkipassView',
  components: { SkipassSelect, SiteHeader, BottomNavigation },
  mixins: [Locales],
  props: {
    msg: String,
  },
  computed: {
    ...mapGetters('booking', [
      'selection',
      'formattedDate',
      'profileConfigNeeded',
    ]),
    complete() {
      // Every person needs to have either a ticket or gear
      return this.selection.persons.every((p) => p.ticket != null || p.boothItemIds.length > 0);
    },
  },
};
</script>
