import axios from 'axios';
import { v4 } from 'uuid';
import firebase from 'firebase/app';
import 'firebase/analytics';
import store from '../store';

function getOrCreateUUID() {
  let hyllUid = localStorage.getItem('hyll-uid');
  if (!hyllUid) {
    hyllUid = v4();
    localStorage.setItem('hyll-uid', hyllUid);
  }

  return hyllUid;
}

function getUtmParams() {
  const params = new URLSearchParams(window.location.search);
  const paramObj = {};
  Array.from(params.keys()).forEach((key) => {
    if (key.indexOf('utm_') !== -1) {
      paramObj[key] = params.get(key);
    }
  });
  return paramObj;
}

async function sendFirebaseTrackingEvent(event, data) {
  const analytics = firebase.analytics();
  return analytics.logEvent(event, data);
}

async function sendHyllTrackingEvent(event, data) {
  const queryString = [];

  // event name
  queryString.push(`event=${ event}`);

  // data
  if (data) {
    Object.keys(data).forEach((key) => {
      if (typeof data[key] === 'object') {
        queryString.push(`${key }=${ JSON.stringify(data[key])}`);
      } else {
        queryString.push(`${key }=${ data[key]}`);
      }
    });
  }

  // eslint-disable-next-line
    return axios.get(encodeURI(`${process.env.VUE_APP_API_URL}/track/?`+ queryString.join('&')));
}

export default {
  async track(event, data) {
    const trackingData = {
      application: 'web_booking',
      ...data,
      ...getUtmParams(),
      device_id: getOrCreateUUID(),
      platform: navigator.platform,
      // user_agent: navigator.userAgent, // Takes up a lot of space. Re-Enable when we get to have a POST request.
      href: window.location.href,
      language: store.getters['localization/language'],
      // connection_type: {
      //     downlink: navigator.connection.downlink,
      //     type: navigator.connection.type,
      //     effectiveType: navigator.connection.effectiveType,
      //     rtt: navigator.connection.rtt,
      //     saveData: navigator.connection.saveData
      // },
    };

    if (process.env.NODE_ENV === 'production') {
      await sendFirebaseTrackingEvent(event, trackingData);
    }
    return sendHyllTrackingEvent(event, trackingData);
  },
};
