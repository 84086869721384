<template>
    <div class="verification-view">
        <site-header :title="selection.resort.name" :sub-title="formattedDate"></site-header>
        <div class="content">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12">
                        <h2 class="title title--centered title--5">{{__('verification_view.title')}}</h2>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-12">
                        <form class="d-flex justify-content-center">
                            <div class="form-card d-flex flex-column">
                                <span class="form-card__title">{{__('verification_view.subtitle')}}</span>
                                <verification :fields="4" @complete="setActivationCode" @change="update" :key="key"></verification>
                                <button class="form-card__link" @click.prevent="resendActivationCode">{{__('verification_view.resend_code')}}</button>
                                <div class="verification-view__error" v-if="showError">{{__('verification_view.error')}}</div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="content__bottom-navigation">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <bottom-navigation
                                :prev-label="__('bottom_navigation.signup')"
                                prev-route="signup"
                                :next-label="__('bottom_navigation.payment')"
                                :before-next="confirm"
                                next-route="payment"
                                :next-disabled="!complete"
                            ></bottom-navigation>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import store from 'storejs';
import BookingApi from '../api/booking';
import SiteHeader from '../components/SiteHeader';
import Verification from '../components/Verification';
import BottomNavigation from '../components/BottomNavigation';
import Locales from '../mixins/locales';

export default {
  name: 'VerificationView',
  components: { SiteHeader, BottomNavigation, Verification },
  mixins: [Locales],
  props: {
    msg: String,
  },
  data() {
    return {
      key: 1,
      activationCode: false,
      deviceId: null,
      showError: false,
    };
  },
  computed: {
    ...mapGetters('booking', [
      'selection',
      'formattedDate',
      'phoneNumber',
    ]),
    complete() {
      return this.activationCode;
    },
  },
  methods: {
    ...mapActions('booking', [
      'updateToken',
    ]),
    async confirm() {
      return BookingApi.confirmPhoneValidation(this.deviceId, this.activationCode)
        .then((response) => {
          this.updateToken(response.data.token);
          return true;
        })
        .catch(() => {
          this.showError = true;
          this.key += 1;
          return false;
        });
    },
    resendActivationCode() {
      BookingApi.sendPhoneValidationSms(this.deviceId, this.phoneNumber);
    },
    setActivationCode(event) {
      this.activationCode = event.code;
    },
    update() {
      this.showError = false;
    },
  },
  mounted() {
    this.deviceId = store.get('hyll-device-id');
  },
};
</script>
