<template>
    <button @click="clickHandler()" class="hamburger">
      <div :class="['hamburger__inner', {'hamburger__inner--active' : this.navOpen}]">
        <div></div>
      </div>
    </button>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters('header', {
      navOpen: 'open',
    }),
  },
  mounted() {},
  methods: {
    ...mapActions('header', [
      'setNavigationStatus',
    ]),
    clickHandler() {
      this.setNavigationStatus(!this.navOpen);
      if (this.navOpen) {
        document.querySelector('body').classList.add('body--fixed');
      } else {
        document.querySelector('body').classList.remove('body--fixed');
      }
    },
  },
};
</script>
