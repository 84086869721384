<template>
  <div class="confirmation-view">
    <site-header :title="selection.resort.name" :sub-title="formattedDate"></site-header>

    <div class="content">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="title title--centered title--5" v-text="__('confirmation_view.title')"></div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <!-- Date -->
            <booking-confirmation-card
                :title="__('confirmation_view.date')"
                low-emphasis
                @edit="gotoEdit('date', 'clicked_edit_date_interval')"
            >
              <p class="confirmation-view__opening">{{ formatDate(selection.date.start) }}</p>
              <p class="confirmation-view__opening"
                 v-if="selection.date.start !== selection.date.end"
              >- {{ formatDate(selection.date.end) }}</p>
            </booking-confirmation-card>

            <!-- Pickup Booth -->
            <booking-confirmation-card
                :title="__('confirmation_view.pickup_booth')"
                low-emphasis
                edit-to="pickup"
                @edit="gotoEdit('pickup', 'clicked_edit_booth')"
            >
              <span class="confirmation-view__booth--resort">{{ selection.resort.name }}</span>
              <div class="divider"></div>

              <p class="confirmation-view__booth--name">{{ selection.booth.name }}</p>
              <p class="confirmation-view__booth--info">{{ selection.booth.info }}</p>
              <p class="confirmation-view__booth--info">{{ selection.booth.address }}</p>
            </booking-confirmation-card>
          </div>

          <div class="col-lg-6">
            <!-- Gear -->
            <booking-confirmation-card
                :title="__('confirmation_view.gear')"
                @edit="gotoEdit('gear', 'clicked_edit_gear')"
            >
              <template v-if="price">
                <div v-for="(profile, index) in price.profiles" :key="index">
                  <div class="confirmation-view__person-title">{{ profile.first_name }} {{ profile.last_name }}</div>
                  <table class="confirmation-view__gear-table">
                    <tr v-for="(reservationItem, index) in profile.prices" :key="index">
                      <td>{{ reservationItem.gear_name }}</td>
                      <td>{{ price.currency }} {{reservationItem.gear_price | price}}</td>
                    </tr>
                  </table>
                </div>

                <table class="confirmation-view__gear-table">
                  <tr class="confirmation-view__gear-table--total">
                    <td v-text="__('confirmation_view.total')"></td>
                    <td>{{ price.currency }} {{ price.total_price | price }}</td>
                  </tr>
                  <tr class="confirmation-view__gear-table--discount" v-if="price.gear_discount !== 0">
                    <td v-text="__('confirmation_view.gear_discount')"></td>
                    <td>{{ price.currency }} {{ price.gear_discount | price }}</td>
                  </tr>
                </table>

                <div class="checkbox checkbox--form confirmation-view__consent">
                  <input id="consent" type="checkbox" class="checkbox__input" value="true"
                         v-model="consent"/>
                  <i></i>
                  <label class="checkbox__label d-block"
                         for="consent" v-html="__('checkout_view.agb_consent_html')"></label>
                </div>
              </template>
            </booking-confirmation-card>
          </div>
        </div>
      </div>

      <div class="content__bottom-navigation">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <bottom-navigation
                  v-if="profileConfigNeeded"
                  :prev-label="__('bottom_navigation.profile_config')"
                  prev-route="profile-config"
                  :next-label="__('bottom_navigation.signup')"
                  next-route="signup"
                  :next-disabled="!consent"
              ></bottom-navigation>
              <bottom-navigation
                  v-else-if="hasTickets"
                  :prev-label="__('bottom_navigation.skipass')"
                  prev-route="skipass"
                  :next-label="__('bottom_navigation.signup')"
                  next-route="signup"
                  :next-disabled="!consent"
              ></bottom-navigation>
              <bottom-navigation
                  v-else
                  :prev-label="__('bottom_navigation.gear')"
                  prev-route="gear"
                  :next-label="__('bottom_navigation.signup')"
                  next-route="signup"
                  :next-disabled="!consent"
              ></bottom-navigation>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import SiteHeader from '../components/SiteHeader';
import BottomNavigation from '../components/BottomNavigation';
import Locale from '../mixins/locales';
import Tracking from '../mixins/tracking';
import BookingConfirmationCard from '../components/BookingConfirmationCard';

export default {
  name: 'ConfirmationView',
  components: { BookingConfirmationCard, SiteHeader, BottomNavigation },
  mixins: [Locale, Tracking],
  data() {
    return {
      consent: false,
    };
  },
  computed: {
    ...mapGetters('booking', [
      'selection',
      'formattedDate',
      'price',
      'profileConfigNeeded',
      'hasTickets',
    ]),
  },
  watch: {
    consent(value) {
      this.track('clicked_reservation_terms_and_conditions', {
        value,
      });
    },
  },
  async created() {
    await this.getPrice();
    await this.track('reached_confirmation');

    this.facebookTrackEvent('InitiateCheckout', {
      currency: this.price.currency,
      value: this.price.total_price,
    });
  },
  methods: {
    ...mapActions('booking', [
      'getPrice',
    ]),
    gotoEdit(route, withEvent) {
      if (withEvent) this.track(withEvent);
      this.$router.push(route);
    },
    formatDate(date) {
      return moment(date).locale(this.__language).format('dddd DD.MM.YYYY');
    },
  },
  filters: {
    price(value) {
      return parseFloat(value).toFixed(2);
    },
  },
};
</script>
