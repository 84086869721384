import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';
import booking from './modules/booking';
import header from './modules/header';
import localization from './modules/localization';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

const vuexPersist = new VuexPersist({
  key: 'hyll-booking',
  storage: window.sessionStorage,
});

export default new Vuex.Store({
  modules: {
    booking,
    header,
    localization,
  },
  plugins: [vuexPersist.plugin],
  strict: debug,
});
