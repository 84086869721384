import axios from 'axios';

export default {
  loadHeader(language) {
    let headers;
    if (process.env.VUE_APP_WEBSITE_API_USER && process.env.VUE_APP_WEBSITE_API_PASSWORD) {
      headers = {
        auth: {
          username: process.env.VUE_APP_WEBSITE_API_USER,
          password: process.env.VUE_APP_WEBSITE_API_PASSWORD,
        },
      };
    }

    return axios.get(`${process.env.VUE_APP_WEBSITE_API_URL}/${language}/header`, headers);
  },
};
