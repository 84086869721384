<template>
  <header
    :class="[
      'header',
      {'header--scrolled' : headerScrolled},
      {'header--hovered' : headerHovered},
      {'header--neg' : isNeg},
      {'header--nav-open' : navOpen}
    ]"
    @mouseenter="setHover(true)"
    @mouseleave="setHover(false)">
    <div class="container">
      <div class="row">
        <div class="col-4 col-lg-2 d-flex align-items-center">
          <a :href="brandLink" class="brand" :class="{'brand--neg' : isNeg}">
            <svg xmlns="http://www.w3.org/2000/svg" width="170" height="40.134" viewBox="0 0 170 40.134">
              <g transform="translate(-17.748 -9.52)">
                <path d="M35.748,23.12Z" transform="translate(13.546 10.235)"/>
                <path d="M23.448,23.12" transform="translate(4.29 10.235)"/>
                <path d="M23.448,23.12" transform="translate(4.29 10.235)"/>
                <path d="M17.748,18.208V40.991a8.453,8.453,0,0,0,8.412,8.588h.175V9.62H26.16A8.453,8.453,0,0,0,17.748,18.208Z"
                      transform="translate(0 0.075)"/>
                <path d="M36.823,9.62h0L36.648,29.6h0V49.579h.175a8.565,8.565,0,0,0,8.412-8.588V18.208A8.453,8.453,0,0,0,36.823,9.62Z"
                      transform="translate(14.224 0.075)"/>
                <path d="M33.612,17.276l-3.33,4.732c-.175.175-.175.175-.351.175h-2.1l-3.68,4.557H43.6l-9.289-9.464c-.351-.175-.526-.175-.7,0Z"
                      transform="translate(4.816 5.738)"/>
                <path d="M84.336,41.692V17.857A8.619,8.619,0,0,0,75.923,9.62h-.175V49.228H98.707a7.678,7.678,0,0,0,7.536-7.361v-.175Z"
                      transform="translate(43.649 0.075)"/>
                <path d="M68.281,25.819h0L55.312,9.52H45.848L64.075,32.3v17h.175a8.649,8.649,0,0,0,8.412-8.412h0V32.479L90.889,9.7H81.425Z"
                      transform="translate(21.147 0)"/>
                <path d="M127.843,41.692H105.936V17.857A8.619,8.619,0,0,0,97.523,9.62h-.175V49.228h22.959a7.592,7.592,0,0,0,7.536-7.536Z"
                      transform="translate(59.905 0.075)"/>
              </g>
            </svg>
          </a>
        </div>
        <div class="col-8 col-lg-10 d-flex justify-content-end" v-if="headerData && headerData.navigationItems">
          <language-switch :class="{'language-switch--visible' : navOpen}" :data="headerData.languageSwitch"></language-switch>
          <navigation :navOpen="navOpen" :navigation-items="headerData.navigationItems" :mode-neg="isNeg"></navigation>
          <sub-navigation :navigation-items="headerData.subNavigationItems" :some-items="headerData.someItems"></sub-navigation>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import Navigation from './Navigation';
import SubNavigation from './SubNavigation';
import LanguageSwitch from './LanguageSwitch';

export default {
  components: {
    Navigation,
    SubNavigation,
    LanguageSwitch,
  },
  props: {
    headerData: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters('header', {
      navOpen: 'open',
    }),
    isNeg() {
      if (this.headerData) {
        if (this.navOpen) {
          return true;
        }
        return this.headerData.mode === 'neg' && !this.headerScrolled;
      }
      return false;
    },
    brandLink() {
      return (this.headerData) ? this.headerData.brandLink : process.env.VUE_APP_HOME;
    },
  },
  data() {
    return {
      headerScrolled: false,
      headerHovered: false,
      headerNeg: false,
      isMobile: false,
    };
  },
  mounted() {
    this.setScrollListener();
  },
  methods: {
    mediaQuery() {
      this.isMobile = window.matchMedia('(max-width: 1200px)').matches;
    },
    setScrollListener() {
      window.addEventListener('scroll', () => {
        if (window.pageYOffset > 0) {
          if (!this.headerScrolled) {
            this.headerScrolled = true;
          }
        } else if (this.headerScrolled) {
          this.headerScrolled = false;
        }
      });
    },
    setHover(val) {
      if (window.pageYOffset === 0 && !this.isMobile) {
        this.headerHovered = val;
      }
    },
    goHome() {
      window.location = process.env.VUE_APP_HOME;
    },
  },
  created() {
    window.addEventListener('resize', this.mediaQuery);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.mediaQuery);
  },
};
</script>
