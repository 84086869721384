<template>
  <div class="profile-config-form">
    <div class="profile-config-form__header">
      <div class="profile-config-form__header--row">
        <div class="profile-config-form__name">{{ profile.firstName }} {{ profile.lastName }}</div>
        <button class="edit-button" @click="$router.push('activity')">
          <span class="edit-button__icon"></span>
        </button>
      </div>

      <span class="title" v-if="formComponents.length > 0">{{ __('profile_config_form.form_title') }}</span>
      <span class="title" v-else>{{ __('profile_config_form.no_questions') }}</span>
    </div>

    <div class="profile-config-form__content">
      <div class="row">
        <component
            v-for="(component, index) in formComponents"
            :key="index"
            :is="component"
            :profile="profile"
        ></component>
      </div>
    </div>
  </div>
</template>

<script>
import bookingApi from '@/api/booking';
import Locales from '@/mixins/locales';

export default {
  name: 'ProfileConfigForm',
  props: {
    profile: Object,
  },
  mixins: [Locales],
  data() {
    return {
      profileConfigQuestions: [],
    };
  },
  created() {
    this.getProfileConfig();
  },
  computed: {
    formComponents() {
      return this.profileConfigQuestions.map(
        (questionType) => () => import(`./form-components/${questionType}.vue`),
      );
    },
  },
  methods: {
    async getProfileConfig() {
      const profileConfigResponse = await bookingApi.getProfileConfig(this.profile.gearTypes);
      this.profileConfigQuestions = profileConfigResponse.data.questions
        .map((question) => question.question_type);
    },
  },
};
</script>
