<script>
import { mapActions } from 'vuex';

export default {
  name: 'ValidationObserverStoreConnector',
  render() { return null; },
  props: {
    valid: Boolean,
  },
  watch: {
    valid(value) {
      this.setProfileConfigValid(value);
    },
  },
  created() {
    this.setProfileConfigValid(this.valid);
  },
  methods: {
    ...mapActions('booking', [
      'setProfileConfigValid',
    ]),
  },
};
</script>
