<template>
    <div class="gear-type-select">
        <product-options v-if="gearOptions" :data="gearOptions" @close="closeOptions"
                         @select="updateOptions"></product-options>
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="d-flex align-items-center">
                        <div class="activity-select__title">{{person.firstName}} {{person.lastName}}</div>
                    </div>
                </div>
            </div>

            <div class="activity-select__activities">
                <div class="row">
                    <div class="col-12 col-sm-6 col-lg-4" v-for="gearType in gearTypes" :key="gearType.id">
                        <select-card :value="gearType"
                                     :name="gearType.name"
                                     :bottom-text="formatPrice(getGearTypePrice(gearType).price)"
                                     :image="gearType.image" :negative="true"
                                     :multiple="true"
                                     :selected="gearType.selected"
                                     :show-configure-button="gearType.selected && gearTypeHasMultipleOptions(gearType)"
                                     @configure="openOptions"
                                     @select="toggle">
                            <template v-slot:configuration v-if="gearType.selected && gearTypeHasMultipleSubtypes(gearType)">
                                <div class="d-inline-block d-sm-block mr-1 mr-sm-0"
                                >{{ getGearTypeItem(gearType).name }}</div>
                                <div v-if="gearType.selected" class="d-inline-block"
                                >{{ getGearTypePrice(gearType).name }}</div>
                            </template>
                        </select-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable no-param-reassign */

import { mapGetters, mapActions } from 'vuex';
import SelectCard from './SelectCard';
import ProductOptions from './ProductOptions';
import BookingApi from '../api/booking';
import Tracking from '../mixins/tracking';
import Locales from '../mixins/locales';

export default {
  name: 'GearTypeSelect',
  components: { SelectCard, ProductOptions },
  mixins: [Locales, Tracking],
  props: {
    person: {
      type: Object,
    },
  },
  data() {
    return {
      id: null,
      currency: null,
      gearOptions: null,
      gearTypes: [],
      selectedPriceIds: [],
    };
  },
  computed: {
    ...mapGetters('booking', [
      'selection',
    ]),
  },
  methods: {
    ...mapActions('booking', [
      'updatePerson',
      'setProfileConfigValid',
      'checkProfileConfigNeeded',
    ]),
    closeOptions() {
      this.gearOptions = null;
    },
    formatPrice(price) {
      return `${this.currency } ${ parseFloat(price).toFixed(2)}`;
    },
    getGearTypeItem(gearType) {
      return gearType.items.find((item) => item.selected);
    },
    getGearTypePrice(gearType) {
      const item = this.getGearTypeItem(gearType);

      if (gearType.selected && item) {
        return item.prices.find((price) => price.selected);
      }
      return { price: gearType.minimum_price };
    },
    gearTypeHasMultipleOptions(gearType) {
      return gearType.items.length > 1 || gearType.items[0].prices.length > 1;
    },
    gearTypeHasMultipleSubtypes(gearType) {
      return gearType.items.length > 1;
    },
    initializeGear(gearTypes) {
      gearTypes.forEach((gearType) => {
        gearType.items.forEach((item) => {
          item.prices.forEach((price) => {
            if (this.person.boothItemIds.indexOf(price.id) !== -1) {
              price.selected = true;
              item.selected = true;
              gearType.selected = true;
            }
          });

          item.prices[item.prices.length - 1].selected = true;
        });

        if (!gearType.selected) {
          gearType.selected = false;
          gearType.name = (gearType.name) ? gearType.name : gearType.id;
          gearType.items[0].selected = true;
        }

        this.gearTypes.push(gearType);
      });
    },
    openOptions(gear) {
      const options = {
        id: gear.id,
        title: this.__('gear_type_select.gear_options_title'),
        tagline: gear.name,
        options: [],
      };

      gear.items.forEach((item) => {
        const option = {
          id: item.id,
          title: item.name,
          description: item.description,
          selected: item.selected || false,
          categories: [],
        };

        item.prices.forEach((price) => {
          option.categories.push({
            id: price.id,
            title: price.name,
            value: this.formatPrice(price.price),
            selected: price.selected || false,
          });
        });

        options.options.push(option);
      });

      this.gearOptions = options;

      this.track('clicked_on_change_gear', {
        gear_id: gear.id,
      });
    },
    updateOptions(event) {
      const gearType = this.gearTypes.find((type) => type.id === event.id);
      gearType.selected = true;

      let trackSelectedItem = null;
      let trackSelectedPrice = null;
      gearType.items.map((item) => {
        if (item.id === event.option) {
          item.selected = true;
          trackSelectedItem = item.id;
          item.prices.map((price) => {
            trackSelectedPrice = price.id;
            price.selected = (price.id === event.category);
            return price;
          });
        } else {
          item.selected = false;
        }
        return item;
      });
      this.gearTypes = [...this.gearTypes];
      this.updateSelectedPrices();
      this.gearOptions = null;
      this.track('clicked_confirm_gear_subtype', {
        gear_id: event.id,
        gear_item: trackSelectedItem,
        price_category: trackSelectedPrice,
      });
    },
    toggle(event) {
      this.gearTypes.forEach((gearType) => {
        if (gearType.id === event.id) {
          if (gearType.selected) {
            gearType.selected = !gearType.selected;
            this.updateSelectedPrices();
          } else if (this.gearTypeHasMultipleOptions(gearType)) {
            this.openOptions(gearType);
          } else {
            gearType.selected = true;
            this.updateSelectedPrices();
          }

          this.track('clicked_on_gear', {
            gear_id: gearType.id,
            selected: gearType.selected,
          });
        }
      });
    },
    updateSelectedPrices() {
      const selectedIds = [];
      this.gearTypes.forEach((gearType) => {
        if (gearType.selected) {
          const price = this.getGearTypePrice(gearType);
          selectedIds.push(price.id);
        }
      });
      this.selectedPriceIds = selectedIds;

      const selectedGearTypeIds = this.gearTypes
        .filter((gearType) => gearType.selected)
        .map((gearType) => gearType.id);

      this.updatePerson({
        id: this.person.id,
        boothItemIds: this.selectedPriceIds,
        gearTypes: selectedGearTypeIds,
      });

      this.setProfileConfigValid(false);
      this.checkProfileConfigNeeded();
    },
  },
  mounted() {
    BookingApi.loadCatalogue(this.selection.resort.id, this.selection.date, this.selection.booth.id, this.person).then((response) => {
      this.currency = response.data.currency;
      this.initializeGear(response.data.catalogue.gear);
      this.$emit('load', { catalogue: response.data.catalogue });
    });
  },
};
</script>
