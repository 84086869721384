import Vue from 'vue';
import VueRouter from 'vue-router';

import firebase from 'firebase/app';
import 'firebase/remote-config';

import { ValidationObserver, ValidationProvider } from 'vee-validate';
import './config/vee-validate';

import router from './router';
import store from './store';

import App from './App';

import './styles/app.scss';
import './config/sentry';

Vue.config.productionTip = false;
Vue.use(VueRouter);

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

/*
load remote config
 */
firebase.initializeApp({
  apiKey: 'AIzaSyD_CaqYK9nM5Ngz8GIuSCCynVNOGYA5wKk',
  authDomain: 'hyll-web-booking.firebaseapp.com',
  databaseURL: 'https://hyll-web-booking.firebaseio.com',
  projectId: 'hyll-web-booking',
  storageBucket: 'hyll-web-booking.appspot.com',
  messagingSenderId: '2057761610',
  appId: '1:2057761610:web:96c158545bef63bf0c47b3',
  measurementId: 'G-X0EHDBVK4T',
});

const remoteConfig = firebase.remoteConfig();
remoteConfig.settings = {
  minimumFetchIntervalMillis: 10,
  fetchTimeoutMillis: 15000,
};

remoteConfig.fetchAndActivate().then(() => {
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app');
});
