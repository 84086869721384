import axios from 'axios';
import moment from 'moment';

export default {
  loadActivities(resortId, date) {
    const start = moment(date.start).format('YYYY-MM-DD');
    const end = moment(date.end).format('YYYY-MM-DD');
    return axios.get(`${process.env.VUE_APP_API_URL}/resorts/${resortId}/activities/?start_date=${start}&end_date=${end}`);
  },
  loadCatalogue(resortId, date, boothId, person) {
    const start = moment(date.start).format('YYYY-MM-DD');
    const end = moment(date.end).format('YYYY-MM-DD');

    // eslint-disable-next-line max-len
    return axios.get(`${process.env.VUE_APP_API_URL}/booths/${boothId}/catalogue/?activity=${person.activity}&start_date=${start}&end_date=${end}&age=${person.age}`);
  },
  loadOpeningInformation(resortId) {
    return axios.get(`${process.env.VUE_APP_API_URL}/resorts/${resortId}/opening_information/`);
  },
  loadPickupBooths(resortId, date, activities) {
    const start = moment(date.start).format('YYYY-MM-DD');
    const end = moment(date.end).format('YYYY-MM-DD');

    // eslint-disable-next-line max-len
    return axios.get(`${process.env.VUE_APP_API_URL}/resorts/${resortId}/booths/?activities=${activities.join(',')}&start_date=${start}&end_date=${end}`);
  },
  loadResorts() {
    return axios.get(`${process.env.VUE_APP_API_URL}/resorts/`);
  },
  getProfileConfig(gearTypes) {
    return axios.get(`${process.env.VUE_APP_API_URL}/profile-config/?geartypes=${gearTypes.join(',')}`);
  },
  getPrice(priceRequestBody) {
    return axios.post(`${process.env.VUE_APP_API_URL}/price/`, priceRequestBody);
  },
  sendPhoneValidationSms(deviceId, phone) {
    return axios.post(`${process.env.VUE_APP_API_URL}/phone-validation/send-sms/`, {
      device_id: deviceId,
      phone,
    });
  },
  confirmPhoneValidation(deviceId, activationCode) {
    return axios.post(`${process.env.VUE_APP_API_URL}/phone-validation/confirm/`, {
      device_id: deviceId,
      activation_code: activationCode,
    });
  },
  createPaymentIntent(token, payload) {
    return axios.post(`${process.env.VUE_APP_API_URL}/create-payment-intent/`, payload, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
  },
  searchResort(term) {
    return axios.get(`${process.env.VUE_APP_API_URL}/resorts/?search=${term}`);
  },
};
