import { render, staticRenderFns } from "./SkipassView.vue?vue&type=template&id=0d11b6b6&"
import script from "./SkipassView.vue?vue&type=script&lang=js&"
export * from "./SkipassView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports