<template>
  <div class="language-switch" :class="{'language-switch--open': openLanguageSwitch}" v-click-outside="hideLanguageSwitch">
    <button class="language-switch__button d-flex d-lg-inline-block" @click.stop="toggleLanguageSwitch()" type="button">
      <span class="icon icon--language"></span><span class="language-switch__label">{{__languageLabel}}</span>
    </button>
    <div class="language-switch__context">
      <ul class="language-switch__list">
        <li class="language-switch__element" v-for="language in availableLanguages" :key="language.key">
          <a
              class="language-switch__link"
              :class="(language.key === __language) ? ' language-switch__link--active ' : ''"
              @click="selectLanguage(language)"
          >
            <span>{{language.label}}</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import { mapActions, mapGetters } from 'vuex';
import axios from 'axios';
import Locale from '../mixins/locales';

export default {
  directives: {
    ClickOutside,
  },
  mixins: [Locale],
  data() {
    return {
      openLanguageSwitch: false,
    };
  },
  props: {
    data: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters('localization', [
      'availableLanguages',
    ]),
  },
  methods: {
    ...mapActions('localization', [
      'setAvailableLanguages',
      'setLanguage',
    ]),
    ...mapActions('header', [
      'setNavigationStatus',
    ]),
    hideLanguageSwitch() {
      this.openLanguageSwitch = false;
    },
    selectLanguage(language) {
      this.setLanguage(language.key);
      this.setNavigationStatus(false);
      document.querySelector('body').classList.remove('body--fixed');
      // set axios language headers
      axios.defaults.headers.common['Accept-Language'] = language.key;
    },
    toggleLanguageSwitch() {
      this.openLanguageSwitch = !this.openLanguageSwitch;
    },
  },
};
</script>
