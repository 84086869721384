import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import store from '../store';

if (process.env.VUE_APP_LOG_SENTRY) {
  Sentry.init({
    Vue,
    release: process.env.VUE_APP_COMMIT_SHA,
    version: process.env.VUE_APP_COMMIT_SHA,
    environment: process.env.VUE_APP_SENTRY_ENV,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    autoSessionTracking: true,
    integrations: [
      new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 0.2,
  });

  // Add vuex state to sentry scope
  Sentry.configureScope((scope) => {
    scope.setContext('state', {
      state: {
        type: 'Vuex',
        value: JSON.stringify(store.state),
      },
    });
  });
}
