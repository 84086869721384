<template>
    <div class="sign-up-view">
        <site-header :title="selection.resort.name" :sub-title="formattedDate"></site-header>
        <div class="content">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12">
                        <h2 class="title title--centered title--5">{{__('signup_view.title')}}</h2>
                    </div>
                </div>
                <div class="row justify-content-center">
                  <form class="form-card col-12 col-sm-10 col-md-6 col-lg-4">
                    <label class="form__label"
                           for="signupMobileInput">{{__('signup_view.mobile_number')}}</label>
                    <div class="form__group d-flex">
                      <div class="select form-card__country-code">
                        <select name="mobileNumberPrefix"
                                id="mobileNumberPrefix"
                                class="select__input"
                                v-model="mobileNumberPrefix">
                          <option v-for="prefix in phonePrefixes" :value="prefix" :key="prefix.label">{{prefix.label}}</option>
                        </select>
                      </div>
                      <input-mask type="tel"
                                  class="input form-card__phone"
                                  name="mobile"
                                  id="signupMobileInput"
                                  mask="(0)99 999 99 99 99" mask-char=" "
                                  v-model="mobileNumber"/>
                    </div>

                    <!-- E-Mail (optional)-->
                    <label class="form__label" for="email">{{__('signup_view.email')}}</label>
                    <div class="form__group">
                      <ValidationProvider tag="span" :name="__('signup_view.email')" rules="email"
                                          v-slot="{ errors }">
                        <input type="email"
                               id="email"
                               name="email"
                               class="input form-card__email"
                               v-model="email"
                               placeholder=""
                               @keyup="updateInfos"/>
                        <span v-if="errors.length > 0" class="input__error">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>

                    <!--Hyll offers-->
                    <div class="checkbox checkbox--form form-card__offers">
                      <input id="offers" checked type="checkbox" class="checkbox__input"
                             v-model="offers"/>
                      <i></i>
                      <label class="checkbox__label"
                             for="offers">{{__('signup_view.hyll_offers')}}</label>
                    </div>

                    <div class="payment-form__error" v-if="error">{{error}}</div>
                  </form>
                </div>
            </div>
            <div class="content__bottom-navigation">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <bottom-navigation
                                :prev-label="__('bottom_navigation.confirmation')"
                                prev-route="confirmation"
                                :next-label="__('bottom_navigation.verification')"
                                :before-next="validatePhone"
                                next-route="verification"
                                :next-disabled="!complete"
                            ></bottom-navigation>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import store from 'storejs';
import { v4 as uuidv4 } from 'uuid';
import InputMask from 'vue-input-mask';
import { PhoneNumberUtil } from 'google-libphonenumber';
import BookingApi from '../api/booking';
import SiteHeader from '../components/SiteHeader';
import BottomNavigation from '../components/BottomNavigation';
import Locales from '../mixins/locales';

export default {
  name: 'SignUpView',
  components: { SiteHeader, BottomNavigation, InputMask },
  mixins: [Locales],
  props: {
    msg: String,
  },
  data() {
    return {
      pohneUtil: null,
      mobileNumberPrefix: null,
      mobileNumber: '',
      offers: true,
      email: '',
      validData: false,
      error: null,
    };
  },
  computed: {
    ...mapGetters('booking', [
      'selection',
      'formattedDate',
      'phoneNumber',
    ]),
    complete() {
      return this.validData;
    },
    phone() {
      return `${this.mobileNumberPrefix.value}${this.mobileNumber.replaceAll(' ', '').substring(3)}`;
    },
    phonePrefixes() {
      return this.phoneUtil.getSupportedRegions().map((region) => {
        const prefix = this.phoneUtil.getCountryCodeForRegion(region);
        return {
          label: `${region} +${prefix}`,
          value: `+${prefix}`,
        };
      });
    },
  },
  watch: {
    mobileNumber() {
      this.updateInfos();
    },
    mobileNumberPrefix() {
      this.updateInfos();
    },
  },
  methods: {
    ...mapActions('booking', [
      'updatePhoneNumber',
      'updateEmail',
    ]),
    updateInfos() {
      this.updatePhoneNumber(this.phone);
      this.updateEmail(this.email);
      try {
        const number = this.phoneUtil.parse(this.phone);
        this.validData = this.phoneUtil.isValidNumber(number);
      } catch (e) {
        this.validData = false;
      }
    },
    getDeviceId() {
      let deviceId = store.get('hyll-device-id');
      if (!deviceId) {
        deviceId = uuidv4();
        store.set('hyll-device-id', deviceId);
      }
      return deviceId;
    },
    async validatePhone() {
      try {
        return await BookingApi.sendPhoneValidationSms(this.getDeviceId(), this.phone);
      } catch (e) {
        if (e.response && e.response.data) this.error = e.response.data.message;
        else this.error = e.message;
        return null;
      }
    },
  },
  created() {
    this.phoneUtil = PhoneNumberUtil.getInstance();
    // Preselect CH Prefix
    this.mobileNumberPrefix = this.phonePrefixes.find((p) => p.value === '+41');
  },
};
</script>
