<template>
    <div class="payment-view">
        <site-header :title="selection.resort.name" :sub-title="formattedDate"></site-header>
        <div class="content">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12">
                        <h2 class="title title--centered title--5">{{ __('payment_view.title')}}</h2>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-12 col-md-8 col-lg-6 col-xl-4">
                        <form class="d-flex justify-content-center">
                            <div class="form-card d-flex flex-column" v-if="stripe">
                                <payment-form :stripe="stripe" @update="setPaymentMethod"></payment-form>
                                <div class="payment-form__error" v-if="error">{{error}}</div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="content__bottom-navigation">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <bottom-navigation
                                :prev-label="__('bottom_navigation.confirmation')"
                                prev-route="confirmation"
                                :next-label="__('bottom_navigation.confirm_payment')"
                                :before-next="pay"
                                next-route="success"
                                :next-disabled="!complete"
                            ></bottom-navigation>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { loadStripe } from '@stripe/stripe-js';
import moment from 'moment';
import BookingApi from '../api/booking';
import SiteHeader from '../components/SiteHeader';
import BottomNavigation from '../components/BottomNavigation';
import Locales from '../mixins/locales';
import Tracking from '../mixins/tracking';
import PaymentForm from '../components/PaymentForm';

export default {
  name: 'PaymentView',
  components: { PaymentForm, SiteHeader, BottomNavigation },
  mixins: [Locales, Tracking],
  props: {
    msg: String,
  },
  data() {
    return {
      stripe: null,
      ready: false,
      paymentMethod: null,
      card: null,
      error: false,
    };
  },
  computed: {
    ...mapGetters('booking', [
      'selection',
      'formattedDate',
      'price',
    ]),
    complete() {
      return this.paymentMethod;
    },
    paymentPayload() {
      const payload = {
        total_price: this.price.total_price,
        payment_method: this.paymentMethod,
        reservation: {
          resort_id: this.selection.resort.id,
          booth_id: this.selection.booth.id,
          start_date: moment(this.selection.date.start).format('YYYY-MM-DD'),
          end_date: moment(this.selection.date.end).format('YYYY-MM-DD'),
          total_price: this.price.total_price,
          profiles: [],
        },
      };
      this.selection.persons.forEach((person, index) => {
        const profile = {
          activity_id: person.activity,
          booth_item_ids: person.boothItemIds,
          ticket: person.ticket,
          profile: {
            is_default: (index === 0),
            first_name: person.firstName,
            last_name: person.lastName,
            email: (index === 0) ? this.selection.email : null,
            phone: (index === 0) ? this.selection.phoneNumber : null,
            age: (person.age) ? parseInt(person.age) : null,
            weight: (person.weight) ? parseInt(person.weight) : null,
            height: (person.height) ? parseInt(person.height) : null,
            shoe_size: (person.shoe_size) ? parseFloat(person.shoe_size) : null,
            shoe_sole_length: (person.shoe_sole_length) ? parseInt(person.shoe_sole_length) : null,
            foot_position: person.foot_position,
            helmet_size: (person.helmet_size) ? parseInt(person.helmet_size) : null,
            level: person.level,
          },
        };
        payload.reservation.profiles.push(profile);
      });
      return payload;
    },
  },
  methods: {
    async init() {
      this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_PK);
    },
    setPaymentMethod(event) {
      this.error = false;
      this.card = event.card;
      if (event.paymentMethod) {
        this.paymentMethod = {
          created: event.paymentMethod.created,
          id: event.paymentMethod.id,
          live_mode: event.paymentMethod.livemode,
          type: event.paymentMethod.type,
          card: {
            brand: event.paymentMethod.card.brand,
            country: event.paymentMethod.card.country,
            expiration_month: event.paymentMethod.card.exp_month,
            expiration_year: event.paymentMethod.card.exp_year,
            funding: event.paymentMethod.card.funding,
            last4: event.paymentMethod.card.last4,
          },
        };
        this.facebookTrackEvent('AddPaymentInfo', {
          currency: this.price.currency,
          value: this.price.total_price,
        });
      } else {
        this.paymentMethod = null;
      }
    },
    pay() {
      return BookingApi.createPaymentIntent(this.selection.token, this.paymentPayload)
        .then((response) => this.stripe.confirmCardPayment(response.data.client_secret, {
          payment_method: {
            card: this.card,
          },
        }).then((result) => {
          if (result.error) {
            this.error = result.error.message;
            return false;
          }

          this.facebookTrackEvent('Purchase', {
            currency: this.price.currency,
            value: this.price.total_price,
          });
          return true;
        }), (error) => {
          this.error = error.response.data.message;
        });
    },
  },
  mounted() {
    this.init();
  },
};
</script>
