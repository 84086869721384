<template>
    <div id="app">
        <site-loader :loaded="loaded"></site-loader>
        <hyll-header :header-data="headerData" v-if="headerLoaded"></hyll-header>
        <router-view></router-view>
    </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import WebsiteApi from './api/website';
import Locale from './mixins/locales';
import HyllHeader from './components/HyllHeader';
import SiteLoader from './components/SiteLoader';

export default {
  name: 'App',
  components: { SiteLoader, HyllHeader },
  mixins: [Locale],
  data() {
    return {
      headerLoaded: false,
      headerData: null,
    };
  },
  computed: {
    ...mapGetters('localization', [
      'availableLanguages',
    ]),
    loaded() {
      return (this.headerLoaded);
    },
  },
  methods: {
    ...mapActions('localization', [
      'setAvailableLanguages',
      'setLanguage',
    ]),
    loadHeader() {
      if (this.__language) {
        WebsiteApi.loadHeader(this.__language).then((response) => {
          this.headerData = response.data;
          this.headerLoaded = true;
        }).catch(() => {
          this.headerLoaded = true;
        });
      }
    },
    initLanguages() {
      this.setAvailableLanguages();
      // check navigator language language
      let language = navigator.language.substring(0, 2);
      // check query params
      const params = new URLSearchParams(window.location.search);
      language = params.get('lang') || language;
      // check if language is available
      const hasLanguage = this.availableLanguages.some((l) => l.key === language);
      if (hasLanguage) {
        this.setLanguage(language);
      } else {
        this.setLanguage(this.availableLanguages[0].key);
      }
    },
  },
  watch: {
    __language(val) {
      if (val) {
        this.loadHeader();
      }
    },
  },
  created() {
    this.initLanguages();
    this.loadHeader();
  },
};
</script>
