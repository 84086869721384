<template>
    <div class="skipass-select">
        <product-options
            v-if="ticketOptions"
            :data="ticketOptions"
            @select="updateOptions"
            @close="ticketOptions = null"
        ></product-options>

        <div class="row">
          <div class="col-12">
            <div class="d-flex align-items-center">
              <div class="activity-select__title">{{person.firstName}} {{person.lastName}}</div>
            </div>
          </div>
        </div>

        <div class="activity-select__activities">
          <div class="row">
            <div class="col-12" v-for="ticketType in tickets" :key="ticketType.id">
              <select-card :value="ticketType"
                           :name="ticketType.display_name"
                           :image="ticketType.image"
                           :negative="true"
                           :multiple="true"
                           pad-image
                           name-above-configuration
                           :selected="ticketType.selected"
                           :show-configure-button="ticketType.selected"
                           :top-text="formatDescription(ticketType)"
                           :bottom-text="formatPrice(ticketType.price)"
                           @select="toggle"
                           @configure="openOptions"
              >
              </select-card>
            </div>
          </div>
      </div>
    </div>
</template>

<script>
/* eslint-disable no-param-reassign */

import { mapActions, mapGetters } from 'vuex';
import ProductOptions from '@/components/ProductOptions';
import BookingApi from '../api/booking';
import Locales from '../mixins/locales';
import SelectCard from './SelectCard';

export default {
  name: 'SkipassSelect',
  props: {
    person: {
      type: Object,
    },
  },
  components: { ProductOptions, SelectCard },
  mixins: [Locales],
  data() {
    return {
      ticketOptions: null,
      tickets: [],
      currency: '',
    };
  },
  computed: {
    ...mapGetters('booking', [
      'selection',
    ]),
  },
  created() {
    BookingApi.loadCatalogue(this.selection.resort.id, this.selection.date, this.selection.booth.id, this.person).then((response) => {
      this.currency = response.data.currency;
      this.initializeTickets(response.data.catalogue.tickets);
    });
  },
  methods: {
    ...mapActions('booking', [
      'updatePerson',
    ]),
    initializeTickets(tickets) {
      tickets.forEach((t) => {
        t.selected = !!this.person.ticket && this.person.ticket.id === t.id;
        t.cards.forEach((c) => { c.selected = !!this.person.ticket && this.person.ticket.card_type_id === c.card_type_id; });

        if (!t.cards.find((c) => c.selected)) {
          t.cards[0].selected = true;
        }

        this.tickets.push(t);
      });
    },
    toggle(event) {
      this.tickets.forEach((t) => {
        if (t.id === event.id) {
          if (t.selected) {
            t.selected = !t.selected;
            this.updateSelectedPrices();
          } else {
            this.openOptions(t);
          }
        }
      });
    },
    openOptions(ticket) {
      const ticketOptions = {
        id: ticket.id,
        title: this.__('skipass_select.ticket_options_title'),
        tagline: '',
        options: [],
      };

      ticket.cards.forEach((card) => {
        ticketOptions.options.push({
          id: card.card_type_id,
          title: card.name,
          description: card.description,
          selected: card.selected,
        });
      });

      this.ticketOptions = ticketOptions;
    },
    updateOptions(event) {
      const ticket = this.tickets.find((t) => t.id === event.id);
      this.tickets.forEach((t) => { t.selected = false; });
      ticket.selected = true;
      ticket.cards.forEach((card) => {
        card.selected = card.card_type_id === event.option;
      });

      this.tickets = [...this.tickets];
      this.ticketOptions = null;

      this.updateSelectedPrices();
    },
    updateSelectedPrices() {
      const selectedTicket = this.tickets.find((t) => t.selected);
      const selectedCard = selectedTicket ? selectedTicket.cards.find((c) => c.selected) : null;

      this.updatePerson({
        id: this.person.id,
        ticket: selectedTicket ? {
          id: selectedTicket.id,
          card_type_id: selectedCard.card_type_id,
          card_id: null,
        } : null,
      });
    },
    formatPrice(price) {
      return `${this.currency } ${ parseFloat(price).toFixed(2)}`;
    },
    formatDescription(ticketType) {
      let description = ticketType.ticket_description;
      if (ticketType.region_description) {
        description += ` | ${ ticketType.region_description}`;
      }
      return description;
    },
  },
};
</script>
