/* eslint no-shadow: ["error", { "allow": ["state"] }] */

import firebase from 'firebase/app';
import 'firebase/remote-config';

import * as types from '../mutation-types';
import api from '../../api/localization';

const AVAILABLE_LANGUAGES = [
  { key: 'en', label: 'English' },
  { key: 'de', label: 'Deutsch' },
  { key: 'fr', label: 'Francais' },
];

// initial state
const state = {
  availableLanguages: [],
  language: null,
  locales: {},
};

// getters
const getters = {
  availableLanguages: (state) => state.availableLanguages,
  language: (state) => state.language,
  languageLabel: (state) => {
    const language = state.availableLanguages.find((l) => l.key === state.language);
    if (language) {
      return language.label;
    }
    return '';
  },
  locales: (state) => state.locales,
};

// actions
const actions = {
  setAvailableLanguages({ commit }) {
    commit(types.LOCALIZATION_SET_AVAILABLE_LANGUAGES, AVAILABLE_LANGUAGES);
  },
  setLanguage({ commit, dispatch }, language) {
    commit(types.LOCALIZATION_SET_LANGUAGE, language);

    if (process.env.NODE_ENV === 'production') {
      dispatch('loadLanguageRemoteConfig', language);
    } else {
      // Don't use remote translations on development
      dispatch('loadLanguageLocal', language);
    }
  },
  loadLanguageLocal({ commit }, language) {
    import(`../../lang/${language}.json`)
      .then((data) => commit(types.LOCALIZATION_SET_LOCALES, data.default));
  },
  loadLanguageRemoteConfig({ commit, dispatch }, language) {
    const remoteConfig = firebase.remoteConfig();
    const identifier = remoteConfig.getValue('locale_resource_identifier')._value;

    api.loadLocales(language, identifier)
      .then((response) => commit(types.LOCALIZATION_SET_LOCALES, response.data))
      .catch(() => dispatch('loadLanguageLocal', language));
  },
};

// mutations
const mutations = {
  [types.LOCALIZATION_SET_AVAILABLE_LANGUAGES](state, availableLanguages) {
    state.availableLanguages = availableLanguages;
  },
  [types.LOCALIZATION_SET_LANGUAGE](state, language) {
    state.language = language;
  },
  [types.LOCALIZATION_SET_LOCALES](state, locales) {
    state.locales = locales;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
