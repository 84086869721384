<template>
  <nav class="navigation d-flex" :class="{'navigation--neg' : modeNeg}" v-if="navigationItems">
    <ul class="navigation__list d-flex" v-if="!navOpen">
      <li class="navigation__item" v-for="item in navigationItems" :key="item.id">
        <a class="navigation__link"
          :class="item.classes"
          :target="item.target"
          :href="item.url"
          @click="trackEvent(item.click_event)">
          <span v-html="item.label" :class="{'d-none d-lg-block' : item.mobile_label}"></span>
          <span v-if="item.mobile_label" v-html="item.mobile_label" class="d-block d-lg-none"></span>
        </a>
      </li>
    </ul>

    <hamburger :class="{'hamburger--neg' : modeNeg}"></hamburger>
  </nav>
</template>
<script>
import tracking from '../mixins/tracking';
import Hamburger from './Hamburger';

export default {
  components: {
    Hamburger,
  },
  mixins: [tracking],
  props: {
    navOpen: {
      type: Boolean,
      default: false,
    },
    modeNeg: {
      type: Boolean,
      default: false,
    },
    navigationItems: {
      type: Array,
      default: null,
    },
  },
  methods: {
    trackEvent(event) {
      if (event) {
        this.track(event);
      }
    },
  },
};
</script>
