<template>
    <div class="verification">
        <input v-for="i in fields"
               :key="'key-' + i"
               :ref="'field-' + i"
               type="tel"
               class="verification__input"
               @keydown="keydown"
               @keyup="checkStep(i)">
    </div>
</template>

<script>
export default {
  props: {
    fields: {
      type: Number,
      default: 4,
    },
  },
  data() {
    return {
      code: '',
    };
  },
  methods: {
    checkStep(i) {
      const current = this.$refs[`field-${ i}`];
      const next = this.$refs[`field-${ i + 1}`];
      this.updateCode();
      if (next && current && current[0].value.length === 1) {
        next[0].focus();
      } else {
        if (current[0].value.length > 1) {
          current[0].value = current[0].value.slice(1, 2);
        }
        if (this.code.length === this.fields) {
          this.$emit('complete', { code: this.code });
        }
      }
      this.$emit('change');
    },
    keydown(event) {
      const index = this.code.length;
      if (event.keyCode === 8 && index > 0) {
        this.$refs[`field-${ index}`][0].value = '';
        this.$refs[`field-${ index}`][0].focus();
        this.updateCode();
      }
    },
    updateCode() {
      this.code = '';
      for (let i = 1; i <= 4; i += 1) {
        this.code += this.$refs[`field-${ i}`][0].value;
      }
    },
  },
};
</script>
