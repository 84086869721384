<template>
  <div class="booking-confirmation-card">
    <div class="booking-confirmation-card__header">
      <div class="booking-confirmation-card__title">{{ title }}</div>
      <button class="edit-button"
        @click="$emit('edit')"
      ><span class="edit-button__icon"></span></button>
    </div>
    <div
        class="booking-confirmation-card__content"
        :class="{ 'low-emphasis': lowEmphasis }"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BookingConfirmationCard',
  props: {
    lowEmphasis: Boolean,
    title: String,
  },
};
</script>
