<template>
    <div class="resort-search" v-click-outside="reset">
        <input v-model="term" class="resort-search__input" :placeholder="__('resort_search.placeholder')" @keyup.enter="enter" @focus="search"/>
        <div class="resort-search__results" v-if="hasResults">
            <div class="resort-search__resort" v-for="resort in resorts" :key="resort.id" @click="select(resort)">
                {{resort.name}}
            </div>
        </div>
    </div>
</template>

<script>

import ClickOutside from 'vue-click-outside';
import BookingApi from '../api/booking';
import Locales from '../mixins/locales';
import Tracking from '../mixins/tracking';

export default {
  name: 'ResortSearch',
  mixins: [Locales, Tracking],
  directives: {
    ClickOutside,
  },
  data() {
    return {
      term: '',
      resorts: [],
    };
  },
  computed: {
    hasResults() {
      return this.resorts && this.resorts.length > 0 && this.term;
    },
  },
  watch: {
    term() {
      this.search();
    },
  },
  methods: {
    reset() {
      this.resorts = [];
    },
    search() {
      BookingApi.searchResort(this.term).then((response) => {
        this.resorts = response.data.data;
      });
      // tracking
      this.track('searched_for_resort', {
        search_term: this.term,
      });
    },
    enter() {
      if (this.resorts.length > 0) {
        this.select(this.resorts[0]);
      }
    },
    select(resort) {
      this.$emit('select', resort);
    },
  },
};
</script>
