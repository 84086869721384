<template>
    <div class="date-view">
        <site-header :title="selection.resort.name" :sub-title="formattedDate"></site-header>

        <div class="content">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12">
                        <h2 class="title title--centered title--5">{{ __('date_view.title') }}</h2>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-12 col-lg-8">
                        <date-selector ref="dateSelector" v-model="date" v-if="openingInformation"
                                       :available-dates="availableDateRange"></date-selector>
                    </div>
                </div>

                <div class="content__bottom-navigation">
                    <div class="container">
                        <div class="row">
                            <div class="col-12">
                                <bottom-navigation
                                    :prev-label="__('bottom_navigation.resort')"
                                    prev-route="destinations"
                                    :next-label="__('bottom_navigation.activity')"
                                    next-route="activity"
                                    :next-disabled="!complete"
                                    @next="onNext"
                                ></bottom-navigation>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex';
import BookingApi from '../api/booking';
import DateSelector from '../components/DateSelector';
import BottomNavigation from '../components/BottomNavigation';
import SiteHeader from '../components/SiteHeader';
import Locales from '../mixins/locales';
import Tracking from '../mixins/tracking';

export default {
  name: 'DateView',
  components: {
    SiteHeader,
    DateSelector,
    BottomNavigation,
  },
  mixins: [Locales, Tracking],
  data() {
    return {
      date: null,
      openingInformation: null,
    };
  },
  computed: {
    ...mapGetters('booking', [
      'selection',
      'formattedDate',
    ]),
    complete() {
      return (this.selection.date.start && this.selection.date.end);
    },
    dateRange() {
      return `${new Date(this.date.start).toString() } - ${ new Date(this.date.end).toString()}`;
    },
    availableDateRange() {
      const ranges = [];
      const openDays = [...this.openingInformation.open_days];
      openDays.forEach((day, index) => {
        if (index === 0) {
          ranges.push({
            start: day,
            end: null,
          });
        } else if (index === (openDays.length - 1)) {
          ranges[ranges.length - 1].end = day;
        } else {
          const day1 = day;
          const day2 = new Date(openDays[index - 1]);
          const diffTime = Math.abs(day1 - day2);
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          if (diffDays > 1) {
            ranges[ranges.length - 1].end = openDays[index - 1];
            ranges.push({
              start: day,
              end: null,
            });
          }
        }
      });
      return ranges;
    },
  },
  methods: {
    ...mapActions('booking', [
      'loadOpeningInformation',
      'selectDate',
    ]),
    onNext() {
      // analytics
      this.track('clicked_finish_date', {
        current_selection: this.dateRange,
      });
    },
  },
  watch: {
    date(val) {
      if (val.start && val.end) {
        // analytics
        this.track('clicked_date', {
          current_selection: this.dateRange,
        });
        this.selectDate(val);
      }
    },
  },
  async mounted() {
    this.date = this.selection.date;

    const openingInformationResponse = await BookingApi.loadOpeningInformation(this.selection.resort.id);
    this.openingInformation = openingInformationResponse.data;

    // Move calendar to either the first available date or the current month depending on which is closer
    this.$nextTick(() => {
      const firstOpenDay = new Date(this.openingInformation.open_days[0]);
      const today = new Date();
      this.$refs.dateSelector.move(firstOpenDay > today ? firstOpenDay : today);
    });

    // analytics
    this.track('reached_date_selection');
  },
};
</script>
