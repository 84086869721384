<template>
    <div class="select-card"
         :class="{
           'select-card--multiple': multiple,
           'select-card--negative': negative,
           'select-card--selected': selected,
           'select-card--pl-0': !padImage && image
          }"
         :style="{'background-image': 'url('+backgroundImage+')'}"
         @click="select()"
    >
        <div class="select-card__selected-backdrop"></div>

        <div class="select-card__inner d-flex align-items-center flex-row">
            <div class="select-card__image-container" v-if="image">
                <img :src="image" class="select-card__image"/>
            </div>
            <div class="select-card__content">
              <div class="select-card__name" v-if="nameAboveConfiguration">{{ name }}</div>
              <div
                  v-if="topText || $slots.configuration"
                  class="select-card__top-text"
              >
                <span v-if="topText">{{ topText }}</span>
                <slot name="configuration"></slot>
              </div>
              <div class="select-card__name" v-if="!nameAboveConfiguration">{{ name }}</div>
              <div
                  v-if="bottomText"
                  class="select-card__bottom-text"
              >
                <span>{{bottomText}}</span>
              </div>
            </div>
            <div class="select-card__actions" v-if="$slots.configuration || multiple">
              <button v-if="showConfigureButton" class="options-button select-card__options-button" @click="configure">
                <span class="options-button__icon"></span
              ></button>
              <div class="spacer"></div>
              <div v-if="multiple" class="checkbox ml-auto">
                <input :checked="selected" type="checkbox" class="checkbox__input"/>
                <i></i>
              </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'SelectCard',
  props: {
    value: {
      type: [Number, String, Object],
    },
    name: {
      type: String,
    },
    topText: {
      type: [String, Number],
    },
    bottomText: {
      type: [String, Number],
    },
    image: {
      type: String,
    },
    backgroundImage: {
      type: String,
    },
    multiple: {
      type: Boolean,
    },
    negative: {
      type: Boolean,
    },
    selected: {
      type: Boolean,
    },
    configuration: {
      type: String,
    },
    showConfigureButton: {
      type: Boolean,
    },
    padImage: {
      type: Boolean,
    },
    nameAboveConfiguration: {
      type: Boolean,
    },
  },
  methods: {
    select() {
      this.$emit('select', this.value);
    },
    configure(event) {
      event.stopPropagation();
      this.$emit('configure', this.value);
    },
  },
};
</script>
