<template>
    <div class="site-loader">
        <div class="site-loader__curtain" :class="{'site-loader__curtain--loaded': loaded}"></div>
    </div>
</template>

<script>
export default {
  name: 'SiteLoader',
  props: {
    loaded: {
      type: Boolean,
    },
  },
};
</script>
